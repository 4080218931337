import {Grid, TextField, Button} from "@mui/material";
import React, {useState, useEffect, ChangeEvent} from "react";

export function JsonUpdate (props: any) {

    const [jsonData, setJsonData] = useState<string>('');

    const convertJsonData = () => {
        setJsonData(JSON.stringify(props.mapping, null, 4));
    }

    const handleJsonData = (event:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setJsonData(event.target.value);
    }

    const setMappingValues = () => {
        let parsingData = JSON.parse(jsonData);
        props.setMappingValues(parsingData);
        alert('저장되었습니다.');
        props.handleDialogClose();
    }

    useEffect(() => {
        convertJsonData();
    }, [])

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 3}}>
            <Grid container spacing={1}>
                <TextField
                    multiline
                    fullWidth
                    minRows={10}
                    maxRows={20}
                    id='json_editor'
                    label='Json Editor'
                    value={jsonData}
                    onChange={(e) => handleJsonData(e)}
                />
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 3,
                    }}
                >
                    <Button
                        variant='contained'
                        sx={{ height: '100%' }}
                        onClick={() => setMappingValues()}
                    >
                        저장
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}