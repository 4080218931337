import TISAxios from './Instance';

interface selectStartInterface {
    name: string;
    writer: string;
}

export type selectStartProcessArray = Array<selectStartInterface>;

export async function StartProcess(name: string, writer: string) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/process/start`, {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function StartAllProcess() {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/process/start-all`,
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function StopProcess(name: string, writer: string) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/process/stop`, {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function StopAllProcess() {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/process/stop-all`,
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function StartSelectProcess(configs:selectStartProcessArray) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/process/start-select`, {configs : configs }
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}