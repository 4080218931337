import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    Grid,
    DialogTitle,
    Slide, Typography,
    TextField, MenuItem,
    Select, IconButton,
    InputLabel,
    FormControl,
} from '@mui/material';
import {TransitionProps} from "@mui/material/transitions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {userListInterface,
    userListArrayType,
    CreateUser,
    UpdateUser
} from '../../api/userAPI';
import {
    userListInitial,
    userValidationInterface,
    userValidationInitial,
    userValidationTextInterface,
    userValidationTextInitial
} from '../../api/definition';

import {executeUpdateJwtAuthenticationService} from "../../api/authenticateAPI";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});
function UserDetail(props: any){

    const [value, setValue] = useState<userListInterface>(userListInitial());
    const [userList, setUserList] = useState<userListArrayType>([]);
    const [userValid, setUserValid] = useState<userValidationInterface>(userValidationInitial);
    const [userValidText, setUserValidText] = useState<userValidationTextInterface>(userValidationTextInitial);
    const [isHide, setHide] = useState<boolean>(true);

    const [userID, setUserID] = useState<string>('');
    const [userPS, setUserPS] = useState<string>('');
    const [userAuthority, setUserAuthority] = useState<string>('');
    const [userState, setUserState] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [userPhone, setUserPhone] = useState<string>('');
    const [userGroup, setUserGroup] = useState<string>('');
    const [userPosition, setUserPosition] = useState<string>('');
    const [passChange, setPasswordChange] = useState<boolean>(false);

    const navigate = useNavigate();
    const getData = () => {
        return {
            'user_id': userID,
            'password': userPS,
            'authority':userAuthority,
            'status':userState,
            'user_name':userName,
            'user_email':userEmail,
            'user_phone':userPhone,
            'user_group':userGroup,
            'user_position':userPosition,
            'isChange':passChange
        }
    }
    const setData = (value: userListInterface) => {
        setUserID(value.user_id);
        setUserPS(value.password);
        setUserAuthority(value.authority);
        setUserState(value.status);
        setUserName(value.user_name);
        setUserEmail(value.user_email);
        setUserPhone(value.user_phone);
        setUserGroup(value.user_group);
        setUserPosition(value.user_position);

    }

    const handlePopupClose = () => {
        props.setOpen(false);
        props.setUpdate(false);
        props.setRefresh();
    }

    const handleCreateUser = () => {

        if(props.update){
            UpdateUser(getData()).then(data => {
                props.setOpen(false);
                props.setUpdate(false);
                executeUpdateJwtAuthenticationService().then(status=>{
                    if(status){
                        alert(data);
                        props.setRefresh();
                    }
                })
            });

        }else{
            CreateUser(getData()).then(data => {
                props.setOpen(false);
                props.setUpdate(false);
                props.setRefresh();
                alert(data);
            })
        }
    }

    const handleUserValidationMapping = (field: string, value:boolean) => {
        setUserValid({
            ...userValid,
            [field]:value
        })
    }

    const handleUserValidationTextMapping = (field: string, value:string) => {
        setUserValidText({
            ...userValidText,
            [field]: value
        })
    }
    const handleUserIDChange = (event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
        const field = event.target.id;
        const ID = event.target.value;
        const regex_email = /^[a-zA-Z][a-zA-Z0-9_]*@?[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
        const regex_id = /^[a-zA-Z][a-zA-Z0-9_]{2,}$/
        if(props.update){
            if(regex_email.test(ID) || regex_id.test(ID)){
                let foundID = userList.find(user => user.user_id === ID);
                if(foundID && ID === value.user_id){
                    handleUserValidationTextMapping(field,'');
                    handleUserValidationMapping(field,true);
                }else if((foundID && ID !== value.user_id) || (ID === 'intube') ){
                    handleUserValidationTextMapping(field,'이미 존재하는 아이디입니다.');
                    handleUserValidationMapping(field,false);
                }else{
                    handleUserValidationTextMapping(field,'사용 가능한 아이디입니다.');
                    handleUserValidationMapping(field,true);
                }
            }else{
                handleUserValidationTextMapping(field,'3자리 이상의 이메일 또는 아이디를 입력해 주세요.');
                handleUserValidationMapping(field,false);
            }
        }else{
            if(regex_email.test(ID) || regex_id.test(ID)){
                let foundID = userList.find(user => user.user_id === ID);
                if((foundID && ID !== value.user_id) || (ID === 'intube')){
                    handleUserValidationTextMapping(field,'이미 존재하는 아이디입니다.');
                    handleUserValidationMapping(field,false);
                }else{
                    handleUserValidationTextMapping(field,'사용 가능한 아이디입니다.');
                    handleUserValidationMapping(field,true);
                }
            }else if(ID === ''){
                handleUserValidationTextMapping(field,'');
                handleUserValidationMapping(field,true);
            }else{
                handleUserValidationTextMapping(field,'3자리 이상의 이메일 또는 아이디를 입력해 주세요.');
                handleUserValidationMapping(field,false);
            }
        }
        setUserID(ID)
    }

    const handleUserPSChange = (event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
        const field = event.target.id;
        const PS = event.target.value;
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=\-\\\]}[{'";:/?.>,<]).{4,}$/;
        if(regex.test(PS)){
            handleUserValidationTextMapping(field,'');
            handleUserValidationMapping(field,true);
        }else{
            handleUserValidationTextMapping(field,'영문, 특수문자, 숫자 포함 4자리 이상 입력');
            handleUserValidationMapping(field,false);
        }
        setUserPS(PS);
    }

    const handleUserNameChange = (event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
        const field = event.target.id;
        const NAME = event.target.value;
        const foundID = userList.find(user => user.user_name === NAME);
        if(NAME.length < 2){
            handleUserValidationTextMapping(field,'2자리 이상입력해주세요.');
            handleUserValidationMapping(field,false);

        }else{
            if(props.update && value.user_name === NAME){
                handleUserValidationTextMapping(field,'');
                handleUserValidationMapping(field,true);
            }else if(props.update && value.user_name !== NAME && foundID){
                handleUserValidationTextMapping(field,'사용자명이 중복입니다.');
                handleUserValidationMapping(field,false);
            }else{
                if(foundID){
                    handleUserValidationTextMapping(field,'사용자명이 중복입니다.');
                    handleUserValidationMapping(field,false);
                }else{
                    handleUserValidationTextMapping(field,'');
                    handleUserValidationMapping(field,true);
                }

            }

        }
        setUserName(NAME);
    }

    const handleUserEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
        const field = event.target.id;
        const EMAIL = event.target.value;
        const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

        if(regex.test(EMAIL)){
            handleUserValidationTextMapping(field,'');
            handleUserValidationMapping(field,true);
        }else{
            handleUserValidationTextMapping(field,'올바른 이메일 주소 형식이 아닙니다.');
            handleUserValidationMapping(field,false);
        }

        setUserEmail(EMAIL);
    }

    const handleUserConfigValiation = () => {
        if(!userValid.user_id || userID === ''){
            alert("아이디를 확인해주세요.");
        }else{
            if((props.update && passChange) && (!userValid.user_ps || userPS === '')){
                alert("비밀번호를 확인해주세요.");
            }else if(!props.update && (!userValid.user_ps || userPS === '')){
                 alert("비밀번호를 확인해주세요.");
            }else{
                if(!userValid.user_name || userName === ''){
                    alert("사용자명을 확인해주세요.");
                }else{
                    if(!userValid.user_email || userEmail === ''){
                        alert("이메일을 확인해주세요.");
                    }else{
                        handleCreateUser();
                    }
                }
            }
        }
    }

    const handlePassWordChange = () => {
        setPasswordChange(!passChange);
    }

    useEffect(() => {
            if(props.update){
                setData(props.value);
                setValue(props.value);
                setUserList(props.users);
                setUserPS('');
            }else{
                setData(userListInitial());
                if(props.users){
                    setUserList(props.users);
                }else{
                    setUserList([]);
                }

            }

        }, [props.update])

    return (
        <Dialog open={props.open}
                maxWidth='sm'
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description">
            <Grid container spacing={1} sx={{
                p : 1,
                borderBottom: 1
            }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <DialogTitle>
                                <Typography variant='h6'>
                                    {props.update ? '계정 수정' : '계정 생성'}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} sx={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'flex-end',
                            alignItems:'center'
                        }}>
                            <Button
                                variant='contained'
                                size='large'
                                onClick={handlePopupClose}
                                sx={{
                                    height:'60%'
                                }}>
                                닫기
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p:2 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                error={!userValid.user_id}
                                required
                                fullWidth
                                disabled={props.update}
                                id='user_id'
                                label='User ID'
                                value={userID}
                                helperText={userValidText.user_id}
                                onChange={e => handleUserIDChange(e)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                {((!props.update) || (passChange)) &&
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <TextField
                                error={!userValid.user_ps}
                                required
                                fullWidth
                                id='user_ps'
                                label='Password'
                                value={userPS}
                                helperText={userValidText.user_ps}
                                type={isHide ? 'password' : 'text'}
                                onChange={e => handleUserPSChange(e)}
                                />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={() => setHide(!isHide)}
                                sx={{ ml:2}}
                                >
                                <VisibilityIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id='authority-select-label'>Authority</InputLabel>
                                <Select
                                    labelId='authority-select-label'
                                    required
                                    fullWidth
                                    id='user_authority'
                                    label='Authority'
                                    value={userAuthority}
                                    onChange={e => setUserAuthority(e.target.value)}
                                    >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="develop">Developer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id='status-select-label'>Status</InputLabel>
                                <Select
                                    labelId='status-select-label'
                                    required
                                    fullWidth
                                    id='user_state'
                                    label='status'
                                    value={userState}
                                    onChange={e => setUserState(e.target.value)}
                                    >
                                    <MenuItem value="Activate">활성</MenuItem>
                                    <MenuItem value="Disabled">비활성</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                error={!userValid.user_name}
                                required
                                fullWidth
                                id='user_name'
                                label='user name'
                                value={userName}
                                helperText={userValidText.user_name}
                                onChange={e => handleUserNameChange(e)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                error={!userValid.user_email}
                                required
                                fullWidth
                                id='user_email'
                                label='user email'
                                value={userEmail}
                                helperText={userValidText.user_email}
                                onChange={e => handleUserEmailChange(e)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                fullWidth
                                id='user_phone'
                                label='user phone'
                                value={userPhone}
                                onChange={e => setUserPhone(e.target.value)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                fullWidth
                                id='user_group'
                                label='user group'
                                value={userGroup}
                                onChange={e => setUserGroup(e.target.value)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                                fullWidth
                                id='user_position'
                                label='user_position'
                                value={userPosition}
                                onChange={e => setUserPosition(e.target.value)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        {props.update &&
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            < Button
                                variant='contained'
                                fullWidth
                                onClick={handlePassWordChange}
                                sx={{
                                        height: '100%'
                                    }}
                                >
                            비밀번호 초기화
                            </Button>
                        </Grid> }
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Button
                                variant='contained'
                                fullWidth
                                onClick={handleUserConfigValiation}
                                sx={{
                                        height: '100%'
                                    }}
                                >
                            저장하기
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default UserDetail;

