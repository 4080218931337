import {Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import React from "react";
import {LanguageMap} from "./Language";
import {objectLRSInterface} from "../../../../api/definition";
import {ActorForm} from "./ActorForm";
import {ActorMap} from "./Actor";
import {ExtensionMap} from "./Extensions";
import {VerbMap} from "./Verb";

export function ObjectMap (props: any) {

    const setObjectMappingValues = (mappingValues: objectLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            object: mappingValues
        });
    }

    const handleObjectChangeMapping = (event: SelectChangeEvent, field: string) => {
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.object,
            [field]: value
        }
        setObjectMappingValues(mappingValues);
    }

    const handleObjectMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace('object_', '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.object,
            [mappingKey]: value
        }
        setObjectMappingValues(mappingValues);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Select
                        required
                        fullWidth
                        value={props.mapping.object.objectType}
                        onChange={e => handleObjectChangeMapping(e, 'objectType')}
                    >
                        <MenuItem value="Activity">Activity</MenuItem>
                        <MenuItem value="Agent">Agent</MenuItem>
                        <MenuItem value="Group">Group</MenuItem>
                        <MenuItem value="SubStatement">SubStatement</MenuItem>
                        <MenuItem value="StatementRef">StatementRef</MenuItem>
                    </Select>
                </Grid>
                {props.mapping.object.objectType === 'Activity' &&
                    <ObjectActivity
                        prefix='object_activity_'
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
                {props.mapping.object.objectType === 'Agent' &&
                    <ActorForm
                        prefix='object_actor_'
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
                {props.mapping.object.objectType === 'Group' &&
                    <ActorForm
                        prefix='object_actor_'
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
                {props.mapping.object.objectType === 'SubStatement' &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                - actor
                            </Typography>
                        </Grid>
                        {props.mapping.object.actor &&
                            <ActorMap
                                prefix='substatement_actor_'
                                mapping={props.mapping.object}
                                setMappingValues={setObjectMappingValues}
                            />
                        }
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                - verb
                            </Typography>
                        </Grid>
                        {props.mapping.object.verb &&
                            <VerbMap
                                prefix='substatement_verb_'
                                mapping={props.mapping.object}
                                setMappingValues={setObjectMappingValues}
                            />
                        }
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                - object
                            </Typography>
                        </Grid>
                        {props.mapping.object.object &&
                            <ObjectSubStatement
                                prefix='substatement_object_'
                                mapping={props.mapping.object}
                                setMappingValues={setObjectMappingValues}
                            />
                        }
                    </Grid>
                }
                {props.mapping.object.objectType === 'StatementRef' &&
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                        <TextField
                            fullWidth
                            id='object_id'
                            label='Object ID'
                            value={props.mapping.object.id}
                            onChange={e => handleObjectMapping(e)}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

export function ObjectActivity (props: any) {

    const setDefinitionValues = (mappingValues: object) => {
        if(props.name) {
            props.setMappingValues({
                ...props.mapping,
                definition: mappingValues
            }, props.name);
        } else {
            props.setMappingValues({
                ...props.mapping,
                definition: mappingValues
            });
        }
    }

    const handleMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace(props.prefix, '');
        let value: string = event.target.value;
        let mappingValues = props.mapping;
        if (mappingKey === 'id') {
            mappingValues = {
                ...mappingValues,
                [mappingKey]: value
            }
        } else {
            mappingValues = {
                ...mappingValues,
                definition: {
                    ...mappingValues.definition,
                    [mappingKey]: value
                }
            }
        }
        if(props.name){
            props.setMappingValues(mappingValues, props.name);
        } else {
            props.setMappingValues(mappingValues);
        }
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        id={props.prefix+'id'}
                        label='Object ID'
                        value={props.mapping.id}
                        onChange={e => handleMapping(e)}
                    />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        id={props.prefix+'type'}
                        label='type'
                        value={props.mapping.definition.type}
                        onChange={e => handleMapping(e)}
                    />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        id={props.prefix+'moreInfo'}
                        label='moreInfo'
                        value={props.mapping.definition.moreInfo}
                        onChange={e => handleMapping(e)}
                    />
                </Grid>
                <LanguageMap
                    object={props.prefix+'definition'}
                    name='name'
                    mapping={props.mapping.definition}
                    setMappingValues={setDefinitionValues}
                />
                <LanguageMap
                    object={props.prefix+'definition'}
                    name='description'
                    mapping={props.mapping.definition}
                    setMappingValues={setDefinitionValues}
                />
                <ExtensionMap
                    object={props.prefix+'definition'}
                    name='extensions'
                    mapping={props.mapping.definition}
                    setMappingValues={setDefinitionValues}
                />
            </Grid>
        </Grid>
    );
}

function ObjectSubStatement (props: any) {

    const setObjectMappingValues = (mappingValues: objectLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            object: mappingValues
        });
    }

    const handleObjectChangeMapping = (event: SelectChangeEvent, field: string) => {
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.object,
            [field]: value
        }
        setObjectMappingValues(mappingValues);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Select
                        required
                        fullWidth
                        value={props.mapping.object.objectType}
                        onChange={e => handleObjectChangeMapping(e, 'objectType')}
                    >
                        <MenuItem value="Activity">Activity</MenuItem>
                        <MenuItem value="Agent">Agent</MenuItem>
                        <MenuItem value="Group">Group</MenuItem>
                    </Select>
                </Grid>
                {props.mapping.object.objectType === 'Activity' &&
                    <ObjectActivity
                        prefix={props.prefix}
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
                {props.mapping.object.objectType === 'Agent' &&
                    <ActorForm
                        prefix={props.prefix}
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
                {props.mapping.object.objectType === 'Group' &&
                    <ActorForm
                        prefix={props.prefix}
                        mapping={props.mapping.object}
                        setMappingValues={setObjectMappingValues}
                    />
                }
            </Grid>
        </Grid>
    );
}

