import {Grid, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import React from "react";
import {actorLRSInterface} from "../../../../api/definition";
import {ActorForm} from "./ActorForm";

export function ActorMap (props: any) {

    const setActorMappingValues = (mappingValues: actorLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            actor: mappingValues
        });
    }

    const handleActorChangeMapping = (event: SelectChangeEvent, field: string) => {
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.actor,
            [field]: value
        }
        setActorMappingValues(mappingValues);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Select
                        required
                        fullWidth
                        value={props.mapping.actor.objectType}
                        onChange={e => handleActorChangeMapping(e, 'objectType')}
                    >
                        <MenuItem value="Agent">Agent</MenuItem>
                        <MenuItem value="Group">Group</MenuItem>
                    </Select>
                </Grid>
                <ActorForm
                    prefix={props.prefix}
                    name='actor'
                    mapping={props.mapping.actor}
                    setMappingValues={setActorMappingValues}
                />
            </Grid>
        </Grid>
    );
}
