import React, {useState} from "react";
import {Button, Grid, MenuItem, Select, TextField, Typography, Paper} from "@mui/material";
import {ActorMap} from "./xAPI/Actor";
import {VerbMap} from "./xAPI/Verb";
import {ObjectMap} from "./xAPI/Object";
import {LayerDialog} from "../../Layout/CustomDialog";
import {JsonUpdate} from "./xAPI/JsonUpdate";
import {MappingPreview} from "./xAPI/Preview";
import {optionalLRSInitial} from "../../../api/definition";
import {ResultMap} from "./xAPI/Result";
import {AttachmentMap} from "./xAPI/Attachements";
import {ContextMap} from "./xAPI/Context";

export function LRSMapping (props: any) {

    const [layerDialogTitle, setLayerDialogTitle] = useState<string>('');
    const [layerDialogTarget, setLayerDialogTarget] = useState<string>('');
    const [layerDialogOpen, setLayerDialogOpen] = useState<boolean>(false);
    const [optionalProperty, setOptionalProperty] = useState<string>('');

    const handleLayerDialogOpen = (target: string, title: string) => {
        setLayerDialogTitle(title);
        setLayerDialogTarget(target);
        setLayerDialogOpen(true);
    }

    const handleLayerDialogClose = () => {
        setLayerDialogTitle('');
        setLayerDialogTarget('');
        setLayerDialogOpen(false);
    }

    const handleMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping,
            [mappingKey]: value
        }
        props.setMappingValues(mappingValues);
    }

    const handleAddOptionalProperty = () => {
        props.setMappingValues({...props.mapping, [optionalProperty]: optionalLRSInitial(optionalProperty)});
    }

    const navigateMappingGuide = () => {
        let url = '/mapping';
        window.open(url);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
            <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Typography variant='h6'>
                                     xAPI 설정
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-end'
                                  }}
                            >
                                <Button
                                    variant='outlined'
                                    sx={{ ml: 1, height: '100%'}}
                                    onClick={() => {handleLayerDialogOpen('json_update','JSON 편집')}}
                                >
                                    JSON 편집
                                </Button>
                                <Button
                                    variant='outlined'
                                    sx={{ ml: 1, height: '100%'}}
                                    onClick={() => {navigateMappingGuide()}}
                                >
                                    맵핑 가이드
                                </Button>
                                <Button
                                    variant='outlined'
                                    sx={{ ml: 1, height: '100%'}}
                                    onClick={() => {handleLayerDialogOpen('mapping_preview','미리보기')}}
                                >
                                    미리보기
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                fullWidth
                                id='output_prefix'
                                label='Prefix URL'
                                value={props.output.prefix}
                                onChange={props.handleOutput}
                                />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                fullWidth
                                id='output_version'
                                label='X-Experience-API-Version'
                                value={props.output.version}
                                onChange={props.handleOutput}
                                />
                        </Grid>
                     </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                fullWidth
                                id='id'
                                label='ID Column'
                                value={props.mapping.id}
                                onChange={(e) => handleMapping(e)}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                fullWidth
                                id='timestamp'
                                label='Timestamp Column'
                                value={props.mapping.timestamp}
                                onChange={(e) => handleMapping(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {props.mapping.actor &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                * actor
                            </Typography>
                        </Grid>
                        <ActorMap
                            prefix='main_actor_'
                            mapping={props.mapping}
                            setMappingValues={props.setMappingValues}
                        />
                    </Grid>
                }
                {props.mapping.verb &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                * verb
                            </Typography>
                        </Grid>
                        <VerbMap
                            prefix='main_verb_'
                            mapping={props.mapping}
                            setMappingValues={props.setMappingValues}
                        />
                    </Grid>
                }
                {props.mapping.object &&
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid item sx={{mb:1}}>
                            <Typography variant='h6'>
                                * object
                            </Typography>
                        </Grid>
                        <ObjectMap
                            prefix='main_object_'
                            mapping={props.mapping}
                            setMappingValues={props.setMappingValues}
                        />
                    </Grid>
                }
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mb:2}}>
            <Grid item sx={{mb:1}}>
                <Typography variant='h6'>
                    optional property
                </Typography>
            </Grid>
            <Grid container spacing={1}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Select
                        required
                        fullWidth
                        value={optionalProperty}
                        onChange={(e) => setOptionalProperty(e.target.value)}
                    >
                        <MenuItem value="result">result</MenuItem>
                        <MenuItem value="context">context</MenuItem>
                        <MenuItem value="attachments">attachments</MenuItem>
                    </Select>
                </Grid>
                <Grid item
                      sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                      }}
                >
                    <Button
                        variant='outlined' size='small'
                        sx={{ ml: 1, height: '100%'}}
                        onClick={handleAddOptionalProperty}
                    >
                        추가
                    </Button>
                </Grid>
            </Grid>
            {props.mapping.result &&
                <ResultMap mapping={props.mapping} setMappingValues={props.setMappingValues}/>
            }
            {props.mapping.attachments &&
                <AttachmentMap mapping={props.mapping} setMappingValues={props.setMappingValues}/>
            }
            {props.mapping.context &&
                <ContextMap mapping={props.mapping} setMappingValues={props.setMappingValues}/>
            }
        </Grid>
            <LayerDialog
                open={layerDialogOpen}
                handleDialogClose={handleLayerDialogClose}
                title={layerDialogTitle}
            >
                {layerDialogTarget === 'json_update' &&
                    <JsonUpdate
                        mapping={props.mapping}
                        setMappingValues={props.setMappingValues}
                        handleDialogClose={handleLayerDialogClose}
                    />
                }
                {layerDialogTarget === 'mapping_preview' &&
                    <MappingPreview
                        mapping={props.mapping}
                    />
                }
            </LayerDialog>
        </Grid>
    );
}