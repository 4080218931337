import {
    Grid,
    TextField,
    IconButton,
    Select,
    SelectChangeEvent,
    MenuItem
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {repositoryAPIHostInterface} from "../../../api/definition";

export function RepositoryAPIHostInput(props: any){
    const handleMappingHost = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index:number) =>{
        let hosts = [...props.value.input_hosts];
        let key = event.target.id.slice(0, -index.toString().length).replace('_input_', '');
        hosts[index] = {
            ...hosts[index],
            [key] : event.target.value,
        };

        props.handleChangeHost({
            ...props.value,
            'input_hosts' : hosts
        });

    }

    const handleSelectChangeEvent = (event: SelectChangeEvent, index: number) => {
        let hosts = [...props.value.input_hosts];

        hosts[index] = {
            ...hosts[index],
            'method' : event.target.value,
        };

        props.handleChangeHost({
            ...props.value,
            'input_hosts': hosts,
        });
    }

    const handleRemoveHost = (index: number) => {
        let hosts = [...props.value.input_hosts];
        hosts.splice(index, 1);
        props.handleChangeHost({
            ...props.value,
            'input_hosts' : hosts
        })
    }

    return (
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        {props.value.input_hosts &&
                            props.value.input_hosts.map((value:repositoryAPIHostInterface, index: number) =>
                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <Select required
                                                    fullWidth
                                                    id={'method_input_' + index}
                                                    value={value.method}
                                                    onChange={e => handleSelectChangeEvent(e, index)}>
                                                <MenuItem value="GET">GET</MenuItem>
                                                <MenuItem value="POST">POST</MenuItem>
                                                <MenuItem value="PUT">PUT</MenuItem>
                                                <MenuItem value="DELETE">DELETE</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item lg={7} md={7} sm={7} xs={7}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'url_input_' + index}
                                                label={'API URL'}
                                                value={value.url}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1} sx={{
                                            display:'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => handleRemoveHost(index)}
                                                >
                                                <RemoveCircleOutlineIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
    )
}