import {Grid, IconButton, TextField, Typography} from "@mui/material";
import React from "react";
import {attachmentsLRSInterface} from "../../../../api/definition";
import {LanguageMap} from "./Language";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export function AttachmentMap (props: any) {

    const setAttachmentMappingValues = (mappingValues: attachmentsLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            attachments: mappingValues
        });
    }

     const handleAttachmentMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace('attachments_', '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.attachments,
            [mappingKey]: value
        }
        setAttachmentMappingValues(mappingValues);
    }

    const handleRemoveAttachmentMapping = () => {
        delete props.mapping.attachments;
        props.setMappingValues({...props.mapping});
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
            <Grid container spacing={1} sx={{mb:1}}>
                <Grid item  sx={{mr:2}}>
                    <Typography variant='h6' sx={{mt:0.4}}>
                        * attachments
                    </Typography>
                </Grid>
                <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleRemoveAttachmentMapping}
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        id='attachments_usageType'
                        label='usageType'
                        value={props.mapping.attachments.usageType}
                        onChange={handleAttachmentMapping}
                    />
                </Grid>
                <LanguageMap
                    object={props.prefix}
                    name='display'
                    mapping={props.mapping.attachments}
                    setMappingValues={setAttachmentMappingValues}
                />
                <LanguageMap
                    object={props.prefix}
                    name='description'
                    mapping={props.mapping.attachments}
                    setMappingValues={setAttachmentMappingValues}
                />
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography variant='subtitle1'>
                        - etc
                    </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='attachments_contentType'
                        label='contentType'
                        value={props.mapping.attachments.contentType}
                        onChange={handleAttachmentMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='attachments_length'
                        label='length'
                        value={props.mapping.attachments.length}
                        onChange={handleAttachmentMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='attachments_sha2'
                        label='sha2'
                        value={props.mapping.attachments.sha2}
                        onChange={handleAttachmentMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='attachments_fileUrl'
                        label='fileUrl'
                        value={props.mapping.attachments.fileUrl}
                        onChange={handleAttachmentMapping}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
