import {
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {resultLRSInterface} from "../../../../api/definition";
import {ExtensionMap} from "./Extensions";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export function ResultMap (props: any) {

    const [resultScoreType, setResultScoreType] = useState<string>('');

    const setResultMappingValues = (mappingValues: resultLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            result: mappingValues
        });
    }

     const handleResultMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace('result_', '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.result,
            [mappingKey]: value
        }
        setResultMappingValues(mappingValues);
    }

    const handleScoreMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace('result_score_', '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.result,
            score: {
                ...props.mapping.result.score,
                [mappingKey]: value
            }
        }
        setResultMappingValues(mappingValues);
    }

    const handleRemoveResultMapping = () => {
        delete props.mapping.result;
        props.setMappingValues({...props.mapping});
    }

    const handleResultScoreMapping = () => {
        setResultMappingValues({
            ...props.mapping.result,
            score:{
                ...props.mapping.result.score,
                [resultScoreType]:""
            }
        });
    }

    const handleRemoveResultScoreMapping = (name : string) => {
        delete props.mapping.result.score[name];
        setResultMappingValues({...props.mapping.result});
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
            <Grid container spacing={1} sx={{mb:1}}>
                <Grid item sx={{mr:2}}>
                    <Typography variant='h6' sx={{mt:0.4}}>
                        * result
                    </Typography>
                </Grid>
                <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleRemoveResultMapping}
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Select
                        labelId="demo-simple-select-label"
                        required
                        fullWidth
                        value={props.mapping.result.score.id}
                        onChange={(e) => setResultScoreType(e.target.value)}
                    >
                        <MenuItem value="scaled">scaled</MenuItem>
                        <MenuItem value="raw">raw</MenuItem>
                        <MenuItem value="min">min</MenuItem>
                        <MenuItem value="max">max</MenuItem>
                    </Select>
                </Grid>
                <Grid item >
                    <Button
                        variant='outlined' size='small'
                        sx={{ ml: 1, height: '100%'}}
                        onClick={handleResultScoreMapping}
                    >
                        추가
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{mt:2}}>
                {'scaled' in props.mapping.result.score &&
                    <ResultScore
                        id='result_score_scaled'
                        name='scaled'
                        value={props.mapping.result.score.scaled}
                        handleScoreMapping={handleScoreMapping}
                        handleRemoveResultScoreMapping={handleRemoveResultScoreMapping}
                    />
                }
                {'raw' in props.mapping.result.score &&
                    <ResultScore
                        id='result_score_raw'
                        name='raw'
                        value={props.mapping.result.score.raw}
                        handleScoreMapping={handleScoreMapping}
                        handleRemoveResultScoreMapping={handleRemoveResultScoreMapping}
                    />
                }
                {'min' in props.mapping.result.score &&
                    <ResultScore
                        id='result_score_min'
                        name='min'
                        value={props.mapping.result.score.min}
                        handleScoreMapping={handleScoreMapping}
                        handleRemoveResultScoreMapping={handleRemoveResultScoreMapping}
                    />
                }
                {'max' in props.mapping.result.score &&
                    <ResultScore
                        id='result_score_max'
                        name='max'
                        value={props.mapping.result.score.max}
                        handleScoreMapping={handleScoreMapping}
                        handleRemoveResultScoreMapping={handleRemoveResultScoreMapping}
                    />
                }
            </Grid>
            <Grid container spacing={1} sx={{mt:2}}>
                <Grid item lg={3} md={2} sm={2} xs={2}>
                    <TextField
                        fullWidth
                        id='result_success'
                        label='success'
                        value={props.mapping.result.success}
                        onChange={handleResultMapping}
                    />
                </Grid>
                <Grid item lg={3} md={2} sm={2} xs={2}>
                    <TextField
                        fullWidth
                        id='result_completion'
                        label='completion'
                        value={props.mapping.result.completion}
                        onChange={handleResultMapping}
                    />
                </Grid>
                <Grid item lg={3} md={2} sm={2} xs={2}>
                    <TextField
                        fullWidth
                        id='result_response'
                        label='response'
                        value={props.mapping.result.response}
                        onChange={handleResultMapping}
                    />
                </Grid>
                <Grid item lg={3} md={2} sm={2} xs={2}>
                    <TextField
                        fullWidth
                        id='result_duration'
                        label='duration'
                        value={props.mapping.result.duration}
                        onChange={handleResultMapping}
                    />
                </Grid>
                <ExtensionMap
                    object='result'
                    name='extensions'
                    mapping={props.mapping.result}
                    setMappingValues={setResultMappingValues}
                />
            </Grid>
        </Grid>
    );
}

function ResultScore(props: any) {

    return (
            <Grid item lg={3} md={2} sm={2} xs={2}>
                <Grid container spacing={1}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                        <TextField
                            fullWidth
                            id={props.id}
                            label={props.name}
                            value={props.value}
                            onChange={props.handleScoreMapping}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}
                    >
                        <IconButton
                                edge='start'
                                color='inherit'
                                onClick={() => props.handleRemoveResultScoreMapping(props.name)}
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                    </Grid>
                </Grid>
            </Grid>
    );

}