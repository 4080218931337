import TISAxios from './Instance';

export interface SinceInterface {
    id: string;
    name: string;
    writer:string;
    repository:string;
    host: string;
    database: string;
    since: string
}

export type SinceArrayType = Array<SinceInterface>;

export async function ListSince() {
    const newData: SinceArrayType = [];
    await TISAxios.post(
        `/tis/since/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { id: string, name: string, username: string, repository:string,host: string,port: string, database:string, since:string; }) =>

                newData.push({
                    'id': row.id,
                    'name': row.name,
                    'writer': row.username,
                    'repository': row.repository,
                    'host': row.host + ':' + row.port,
                    'database': row.database,
                    'since': row.since
                })
            )
        }
    }).catch(function (error) {

    });
    return newData;
}

export async function ResetSince(value:SinceInterface) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/since/reset`, value
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function ResetAllSince() {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/since/reset-all`,
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}