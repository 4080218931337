import {Grid, TextField} from "@mui/material";
import React from "react";

export function APIInputConfig(props: any) {
    return (
            <Grid container spacing={1}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                    fullWidth
                    id='input_api_request_type'
                    label='Request Type (json, url, query)'
                    value={props.input.request_type}
                    onChange={props.handleInput}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                    fullWidth
                    id='input_api_response_type'
                    label='Response Type (json, xml)'
                    value={props.input.response_type}
                    onChange={props.handleInput}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <TextField
                    fullWidth
                    id='input_api_id'
                    label='Input Id'
                    value={props.input.id}
                    onChange={props.handleInput}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <TextField
                    fullWidth
                    id='input_api_since'
                    label='Input Since'
                    value={props.input.since}
                    onChange={props.handleInput}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    required
                    multiline
                    fullWidth
                    id='input_api_query'
                    label='Input Query'
                    value={props.input.query}
                    onChange={props.handleInput}
                    />
                </Grid>
            </Grid>
    )
}