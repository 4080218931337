import TISAxios from './Instance';

export interface TestResultInterface {
    name: string,
    time: string,
    result: Array<string>,
    error: boolean,
}

export async function TestProcess(name: string, writer: string) {
    const testResult: Array<TestResultInterface> = [{
        name: '',
        time: '',
        result: [],
        error: false,
    }]
    await TISAxios.post(
        `/tis/test/process`, {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            Object.assign(testResult, response.data);
        }
    }).catch(function (error) {
        Object.assign(testResult, {
            'name': '',
            'time': '',
            'result': [error],
            'error': true,
        });
    });
    return testResult;
}