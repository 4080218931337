import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Grid,
    IconButton,
    Paper,
    Slide,
    Typography
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {TransitionProps} from '@mui/material/transitions';
import {DataGrid, GridCellValue, GridColDef} from '@mui/x-data-grid';

import {codeArrayType, DeleteCode, ListCode} from '../../api/codeAPI';
import {ConfirmDialog} from '../Layout/CustomDialog';
import {useNavigate} from "react-router-dom";
import Loading from "../Layout/Loading";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Code() {
    const [codeData, setCodeData] = useState<codeArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [deleteCodeOpen, setDeleteCodeOpen] = useState<boolean>(false);
    const [deleteTargetCode, setDeleteTargetCode] = useState<string>('');
    const [deleteTargetWriter, setDeleteTargetWriter] = useState<string>('');

    const codeColumns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'writer', hide: true},
        { field: 'code', headerName: '코드', flex: 0.5 },
        { field: 'name', headerName: '코드이름', flex: 1 },
        {
            field: 'update', headerName: '수정', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {navigateUpdateCode(params.getValue(params.id, 'code'),params.getValue(params.id, 'writer'))}}
                    color='primary'
                    aria-label='update-code'
                >
                    <CreateIcon />
                </IconButton>
            )
        },
        {
            field: 'delete', headerName: '삭제', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleDeleteCodeOpen(params.getValue(params.id, 'code'), params.getValue(params.id, 'writer'))}}
                    color='error'
                    aria-label='delete-code'
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
    ]

    const navigate = useNavigate();

    const navigateUpdateCode = (code: GridCellValue, writer: GridCellValue) => {
        let url = '/update_code/' + code;
        navigate(url, {state:{code:code, writer:writer}});
    };

    const handleDeleteCodeOpen = (code: GridCellValue, writer: GridCellValue) => {
        if (typeof code === 'string' && typeof writer === 'string') {
            setDeleteTargetCode(code);
            setDeleteTargetWriter(writer);
            setDeleteCodeOpen(true);
        } else {
            alert(`${code} is not string type`);
        }
    }

    const handleDeleteCodeClose = () => {
        setDeleteCodeOpen(false);
    }

    const handleDeleteCode = () => {
        setDeleteCodeOpen(false);
        if (deleteTargetCode !== '') {
            DeleteCode(deleteTargetCode, deleteTargetWriter).then(value => {
                alert(value);
                handleRefresh();
            })
        } else {
            alert('empty name')
        }
    }

    const handleRefresh = () => {
        setLoading(true);
        ListCode().then(value => {
            setCodeData(value);
            setLoading(false);
        })
    }

    useEffect(() => {
        setLoading(true);
        ListCode().then(value => {
            setCodeData(value);
            setLoading(false);
        })
    }, [])

    if(loading) return <Loading />;

    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '10%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={10} md={10} sm={10} xs={10} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ml: 1}} variant='h5'>코드관리</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <IconButton
                                    onClick={handleRefresh}
                                    aria-label='refresh'
                                >
                                    <RefreshIcon/>
                                </IconButton>
                                <Button
                                    variant='contained'
                                    onClick={() => {navigateUpdateCode('new', '')}}
                                    sx={{ height: '100%',  boxShadow:"none"}}
                                >
                                    신규생성
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '90%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            height: 650,
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            rows={codeData}
                            columns={codeColumns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={deleteCodeOpen}
                handleDialogAction={handleDeleteCode}
                handleDialogClose={handleDeleteCodeClose}
                title={'Delete Config'}
                text={deleteTargetCode + '을(를) 정말로 제거하시겠습니까?'}
            />
        </Container>
    );
}

export default Code;