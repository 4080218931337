import React from "react";
import {Button, Grid, IconButton, TextField, Typography, Paper} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {mappingBaseInterface, mappingBaseInitial} from "../../../api/definition";

export function BaseMapping (props: any) {
    const handleMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        let list = [...props.mapping];
        list[index] = {
            ...list[index],
            [event.target.id.slice(0, -index.toString().length)]: event.target.value,
        };
        props.setMappingValues(list);
    };

    const handleAddMapping = () => {
        props.setMappingValues([...props.mapping, mappingBaseInitial()])
    }

    const handleRemoveMapping = (index: number) => {
        let list = [...props.mapping];
        list.splice(index, 1);
        props.setMappingValues(list);
    };

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
            <Grid container spacing={1}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    width: '100%',
                    bgcolor: '#eeeeee',
                    mb: 1,
                    mt: 1
                }}
            >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Typography variant='subtitle1'>
                                Mapping - length: {props.mapping ? props.mapping.length : 0}
                            </Typography>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                              }}
                        >
                            <Button
                                variant='contained'
                                onClick={handleAddMapping}
                            >
                                매핑추가
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {props.mapping &&
                props.mapping.map((value: mappingBaseInterface, index: number) =>
                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                required
                                fullWidth
                                id={'input_name'+index}
                                label='Input Name'
                                value={value.input_name}
                                onChange={e => handleMapping(e, index)}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                required
                                fullWidth
                                id={'output_name'+index}
                                label='Output Name'
                                value={value.output_name}
                                onChange={e => handleMapping(e, index)}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <TextField
                                required
                                fullWidth
                                id={'type'+index}
                                label='Type'
                                value={value.type}
                                onChange={e => handleMapping(e, index)}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <TextField
                                fullWidth
                                id={'splitter'+index}
                                label='Splitter'
                                value={value.splitter}
                                onChange={e => handleMapping(e, index)}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={() => handleRemoveMapping(index)}
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            </Grid>
        </Grid>
    );
}