import {CircularProgress, Container} from "@mui/material";
import React from "react";

function Loading(){
    return (
        <Container maxWidth='xl' sx={{
            mt: 10, mb: 2, height: 800, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}>
            <CircularProgress size={700}/>
        </Container>
    );
}

export default Loading;