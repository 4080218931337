// 기본 Input Type
import {
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem, Select,
    SelectChangeEvent,
    TextField,
    Typography, Tab, Tabs, } from "@mui/material";
import React, {SyntheticEvent, useEffect, useState, useCallback, useMemo} from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
    inputAPIConfigInterface,
    inputAPIConfigInitial,
    inputAPIAuthorizationInterface,
    inputAPIAuthorizationBasicAuthInterface,
    inputAPIAuthorizationBasicAuthInitial,
    inputAPIAuthorizationInitial,
    inputAPIParamsInitial,
    inputAPIParamsInterface,
    inputAPIAuthorizationBearerTokenInterface,
    inputAPIAuthorizationBearerTokenInitial
} from "../../../api/definition";
import {APIInputAuth} from "./InputDetail/apiInputAuth";
import {APIInputConfig} from "./InputDetail/apiInputConfig";
import {APIInputHeaders} from "./InputDetail/apiInputHeaders";
import {APIInputParams} from "./InputDetail/apiInputParams";
import { isInputAPIInterface} from '../../../api/configAPI';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}
            hidden={value !== index}>
            <div
                role="tabpanel"
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </div>
        </Grid>
    )
}

export function ApiInput(props: any) {
    const [config, setConfig] = useState<inputAPIConfigInterface>(props.input.config);
    const [authorization, setAuth] = useState<inputAPIAuthorizationInterface>(props.input.authorization);
    const [headers, setHeaders] = useState<Array<inputAPIParamsInterface>>(props.input.headers);
    const [params, setParams] = useState<Array<inputAPIParamsInterface>>(props.input.params);
    const [index, setIndex] = useState(0);

    const handleChangeTab = useCallback((event: SyntheticEvent, index: number) => {
        setIndex(index);
    }, []);

    const handleInputConfig = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputKey = event.target.id;
        inputKey = inputKey.replace('input_api_', '');
        let value: string = event.target.value;

        setConfig((preConfig) => ({
            ...preConfig,
            [inputKey]: value
        }));
    }, []);

    const handleInputAuth = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputKey = event.target.id;
        let info = inputKey.split('_');
        let authKey = info[2];
        let changeKey = info[3];
        let value: string = event.target.value;

        setAuth((preAuth) => ({
            ...preAuth,
            [authKey] : {
                ...preAuth.auth,
                [changeKey]: value
            }
        }));
    }, []);

    const handleInputAuthType = useCallback((event: SelectChangeEvent) => {
        setAuth((preAuth) => ({
            ...preAuth,
            'auth_type' : event.target.value
        }));
    }, []);

    const handleInputParam = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>, index:number) => {
        let inputKey = event.target.id;
        let info = inputKey.split('_');
        let id = info[2];
        let changeKey = info[3];
        let value: string = event.target.value;

        if(id === 'params') {
            setParams((preParam) => {
                const updateParams = [...preParam];
                updateParams[index] = {
                    ...updateParams[index],
                    [changeKey] : value
                };

                return updateParams;
            });

        }else{
            setHeaders((preHeader) => {
                const updateHeaders = [...preHeader];
                updateHeaders[index] = {
                    ...updateHeaders[index],
                    [changeKey] : value
                };

                return updateHeaders;
            });
        }
    }, []);


    const handleInputParamType = useCallback((event: SelectChangeEvent, inputKey:string, index:number) => {
        let value = event.target.value;
        if (inputKey === 'params') {
                setParams((preParam) => {
                    const updatedParams = [...preParam];
                    updatedParams[index] = {
                        ...updatedParams[index],
                        'type' : value
                    };
                    return updatedParams;
                });
            } else {
                setHeaders((preHeader) => {
                    const updatedHeaders = [...preHeader];
                    updatedHeaders[index] = {
                        ...updatedHeaders[index],
                        'type': value
                    };
                    return updatedHeaders;
                });
            }

    }, []);

    const handleInputParamAdd = useCallback((key: string) => {
        if (key === 'params') {
            setParams((preParams) => [...preParams, inputAPIParamsInitial()]);
        } else if (key === 'headers') {
            setHeaders((preHeaders) => [...preHeaders, inputAPIParamsInitial()]);
        }
    }, []);

    const handleInputParamRemove = useCallback((index: number, key: string) => {
        if(key === 'params'){
            setParams((preParams) => {
                const updatedParams = [...preParams];
                updatedParams.splice(index, 1);
                return updatedParams;
            });
        }else{
            setHeaders((preHeaders) => {
                const updatedHeaders = [...preHeaders];
                updatedHeaders.splice(index, 1);
                return updatedHeaders;
            });
        }

    }, []);

    useEffect(() => {
        console.log(props.input);
        if(isInputAPIInterface(props.input)) {
            setConfig(props.input.config);
            setAuth(props.input.authorization);
            setHeaders(props.input.headers);
            setParams(props.input.params);
        }
    }, [props.input]);

    useEffect(() => {
        props.handleInput('config', config);
    }, [config])

    useEffect(() =>{
        props.handleInput('authorization', authorization);
    }, [authorization])

    useEffect(() =>{
        props.handleInput('headers', headers);
    }, [headers])

    useEffect(() =>{
        props.handleInput('params', params);
    }, [params])

    return (
        <Grid container spacing={1} sx={{mt:1}}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <Tabs value={index} onChange={handleChangeTab}>
                    <Tab label="Config" value={0}/>
                    <Tab label="Authorization" value={1} />
                    <Tab label="Headers" value={2} />
                    <Tab label="Params" value={3} />
                </Tabs>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} sx={{ display: 'flex',
                           justifyContent: 'flex-end', p:2}}>
            {(index === 2 || index === 3) &&
                <Button variant='contained'
                        onClick={e => handleInputParamAdd(index === 2 ? 'headers' : 'params')}
                >
                매핑추가
                </Button>
                }
            </Grid>
            {useMemo(() => (
                <CustomTabPanel value={index} index={0}>
                    <APIInputConfig input={config} handleInput={handleInputConfig}/>
                </CustomTabPanel>
            ), [config, handleInputConfig, index])}
            {useMemo(() => (
                <CustomTabPanel value={index} index={1}>
                    <APIInputAuth input={authorization} handleInput={handleInputAuth} handleType={handleInputAuthType}/>
                </CustomTabPanel>
            ), [authorization, handleInputAuth, handleInputAuthType,index])}
            {useMemo(() => (
                <CustomTabPanel value={index} index={2}>
                    <APIInputHeaders input={headers} handleInput={handleInputParam} handleType={handleInputParamType}
                                    handleAdd={handleInputParamAdd} handleRemove={handleInputParamRemove}/>
                </CustomTabPanel>
            ), [headers, handleInputParam, handleInputParamType,index])}
            {useMemo(() => (
                <CustomTabPanel value={index} index={3}>
                    <APIInputParams input={params} handleInput={handleInputParam} handleType={handleInputParamType}
                                    handleAdd={handleInputParamAdd} handleRemove={handleInputParamRemove}/>
                </CustomTabPanel>
            ), [params, handleInputParam, handleInputParamType,index])}
        </Grid>
    )
}

