import React, {useEffect, useState, useMemo} from 'react';
import {
    Button,
    Container,
    Grid, Paper,
    TextField,
    Typography,
    Select,
    MenuItem, Chip, Stack
} from '@mui/material';

import {
    configInterface,
    instanceOfConfigInterface,
    CreateConfig,
    UpdateConfig,
    CheckConfig, isInputAPIInterface,
} from '../../api/configAPI';

import {useNavigate, useLocation} from "react-router-dom";

import {
    inputBaseInterface,
    inputBaseInitial,
    inputESInterface,
    inputAPIInterface,
    inputAPIInitial,
    outputLRSInterface,
    outputLRSInitial,
    outputESInterface,
    outputESInitial,
    mappingBaseInterface,
    mappingBaseInitial,
    mappingLRSInterface,
    mappingLRSInitial,
    inputAPIConfigInterface,
    inputAPIAuthorizationInterface,
    inputAPIParamsInterface,
} from "../../api/definition";

import {repositoryListInterface,
    repositoryArrayType,
    ListRepository,
    CreateListRepository} from "../../api/repositoryAPI";
import {ConfirmDialog} from '../Layout/CustomDialog';
import {BaseInput} from "./Input/BaseInput";
import {BaseMapping} from "./Mapping/BaseMapping";
import {JsonDownload, JsonUpload} from "../Layout/CustomButton";
import {LRSMapping} from "./Mapping/LRSMapping";
import {ESInput} from "./Input/ESInput";
import {ESOutput} from "./Output/ESOutput";
import RepositorySelectBox from "../BoxComponent/RepositorySelectBox";
import {ApiInput} from "./Input/ApiInput";

function RegisterConfig() {

    const configName = useLocation().state.configName;
    const [writer, setWriter] = useState<string>(useLocation().state.writer);
    const [update, setUpdate] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [cron, setCron] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [inputType, setInputType] = useState<string>('mysql');
    const [input, setInput] = useState<inputBaseInterface | inputESInterface | inputAPIInterface>(inputBaseInitial());
    const [outputType, setOutputType] = useState<string>('elasticsearch');
    const [output, setOutput] = useState<outputESInterface | outputLRSInterface>(outputESInitial());
    const [mappingValues, setMappingValues] = useState<Array<mappingBaseInterface> | mappingLRSInterface>([mappingBaseInitial()]);
    const [repository, setRepositorys] = useState<repositoryArrayType>([]);
    const [status, setStatus] = useState<boolean>(false);

    const [repositoryList, setRepositoryList] = useState<repositoryArrayType>([]);
    const [dialogOpen, setDiaLogOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>('');

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();
    useEffect(() => {
        if(configName !== undefined && configName !== 'new') {
            CheckConfig(configName, writer).then((value: any) => {
                setData(value);
                setUpdate(true);
            })
        }
    }, [configName])

    const getData = () => {

        let data: configInterface = {
            'name': name,
            'cron': cron,
            'description' : description,
            'input_type': inputType,
            'input': input,
            'output_type': outputType,
            'output': output,
            'mapping': mappingValues,
            'repository': repository,
            'status':status,
            'writer':writer
        };

        return data;
    }

    const setData = (value: any) => {
        try {
            if (instanceOfConfigInterface(value)) {
                if(value.input_type === 'api'){
                    setInput(inputAPIInitial());
                }
                setName(value.name);
                setCron(value.cron);
                setDescription(value.description);
                setInputType(value.input_type);
                if(value.input_type === 'api' && isInputAPIInterface(value.input)){
                    handleInputAPI('config', value.input.config);
                    handleInputAPI('authorization', value.input.authorization);
                    handleInputAPI('headers', value.input.headers);
                    handleInputAPI('params', value.input.params);
                }else{
                    setInput(value.input);
                }
                setOutputType(value.output_type);
                setOutput(value.output);
                setMappingValues(value.mapping);
                setRepositorys(value.repository);
                setStatus(value.status);
                setWriter(value.writer);

            } else {
                alert('data is not valid1');
            }
        } catch (error) {
            alert('data is not valid2');
        }
     }

    const navigateConfigList = () => {
        let url = '/';
        navigate(url);
    }
    const handleName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName((event.target as HTMLTextAreaElement).value);
    };

    const handleCron = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCron((event.target as HTMLTextAreaElement).value);
    };

    const handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((event.target as HTMLTextAreaElement).value);
    };

    const handleInputType = (type:string) => {
        let preType = type;
        setInputType(type);

        // 타입 변경시에만 input 초기화
        if (preType !== inputType) {
            if(type === 'api') {
                setInput(inputAPIInitial());
            }else{
                setInput(inputBaseInitial());
            }
        }
    };

    const handleOutputType = (input_type: string, output_type:string) => {
        let preType = output_type;
        setOutputType(output_type);

        // 타입 변경시에만 output, mapping 초기화
        if (preType !== outputType) {
            if(output_type === 'lrs') {
                if(input_type !== 'api') {
                    setInput({
                        ...input,
                        ['id']: ''
                    });
                }
                setOutput(outputLRSInitial());
                setMappingValues(mappingLRSInitial());
            }else{
                setOutput(outputESInitial());
                setMappingValues([mappingBaseInitial()]);
            }
        }
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputKey = event.target.id;
        inputKey = inputKey.replace('input_', '');
        let value: string = event.target.value;
        setInput({
            ...input,
            [inputKey]: value
        });
    };

    const handleInputAPI = (key: string, value:inputAPIConfigInterface|inputAPIAuthorizationInterface|Array<inputAPIParamsInterface>) => {
        setInput(prevInput => ({
            ...prevInput,
            [key]: value
        }))
    }

    const handleOutput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let outputKey = event.target.id;
        outputKey = outputKey.replace('output_', '');
        let value: string = event.target.value;
        setOutput({
            ...output,
            [outputKey]: value
        });
    };
    const handleCreateConfig = () => {
        if(name !== undefined && name !== '' && repository.length !== 0) {
            if (configName !== undefined && configName !== 'new') {
                UpdateConfig(getData()).then(value => {
                    alert(value);
                })
            } else {
                CreateConfig(getData()).then(value => {
                    if(value.name === ''){
                        alert(value.success);
                    }else{
                        if(value.name === 'userFileNotFoundException'){
                            setMessage(value.error);
                            setOpen(true);
                        }else{
                            alert(value.error);
                        }
                    }
                })
            }
        }else{
            alert('프로세스 이름과 저장소를 선택해 주세요.');
        }
    }

    const handleRepositorySelect = () => {
        let replist : repositoryArrayType = [];
        ListRepository().then(value => {
            replist = value.filter(data => data.status === 'Activate');
            setRepositoryList(replist);
            setDiaLogOpen(true);
            setType(type);
        });
    }

    const handleRepositorySelectMapping = (selectRepository:repositoryArrayType) => {
        setRepositorys(selectRepository);
    }
    const handleRepositoryDelete = (index: number) => {
        let list = [...repository];
        list.splice(index, 1);
        setRepositorys(list);
    }

    const handleRepositoryCreate = () => {
        setOpen(false);

        CreateListRepository(repository).then(value => {
           if(repository.length !== 0){
               setRepositorys(value);
               alert("저장소 생성이 완료되었습니다. 프로세스를 생성해 주세요.");
           }
        });
    }

    const handleCloseDialog = () =>{
        setOpen(false);
    }
    console.log(getData());
    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                <Grid container spacing={1}>
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                            <Typography sx={{ml: 1}} variant='h6'>{update ? '프로세스 수정':'새 프로세스 생성'}</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}
                        >
                            <JsonUpload setData={setData} />
                            <JsonDownload name={name} getData={getData} />
                            <Button
                                variant='contained'
                                onClick={navigateConfigList}
                                sx={{ height: '100%', ml: 1 }}
                            >
                                목록보기
                            </Button>
                            <Button
                                variant='contained'
                                onClick={handleCreateConfig}
                                sx={{ height: '100%', ml: 1 }}
                            >
                                저장하기
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <TextField
                            required
                            fullWidth
                            disabled={update}
                            id='name'
                            label='name'
                            value={name}
                            onChange={handleName}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <TextField
                            fullWidth
                            id='cron'
                            label='cron'
                            value={cron}
                            onChange={handleCron}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Select
                                            required
                                            fullWidth
                                            name='status'
                                            value={status ? 'activate' : 'disabled' }
                                            onChange={e => setStatus(e.target.value === 'activate')}
                            >
                                <MenuItem value='activate'>활성</MenuItem>
                                <MenuItem value='disabled'>비활성</MenuItem>
                            </Select>
                </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                        <TextField
                            fullWidth
                            id='description'
                            label='description'
                            value={description}
                            onChange={handleDescription}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Paper sx={{
                    p: 1,
                    width:'100%',
                    bgcolor:'#eeeeee',
                    mt:2
                }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt : 1}}>
                    <Typography variant='h6'>
                        * 저장소 설정
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt : 1}}>
                    <Button
                        size="large"
                        variant='contained'
                        onClick={handleRepositorySelect}
                    >
                        저장소 선택
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt : 1}}>
                    <Stack direction="row" spacing={1}>
                        { repository &&
                            repository.map((value:repositoryListInterface, index:number) =>
                                <Chip
                                    label={value.name}
                                    variant="outlined"
                                    onDelete={() => handleRepositoryDelete(index)}
                                />
                        )
                        }
                    </Stack>
                </Grid>
            </Paper>
            <Paper sx={{
                p: 1,
                width:'100%',
                bgcolor:'#eeeeee',
                mt:2,
            }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1}}>
                    <Typography variant='subtitle2'>
                        * Input 설정
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <TextField
                                disabled
                                id="input_type"
                                label="Input Type"
                                fullWidth
                                value={inputType}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {useMemo(() => (input && (inputType === 'mysql' || inputType === 'mssql' || inputType === 'tibero' || inputType === 'postgresql') &&
                <BaseInput input={input} outputType={outputType} handleInput={handleInput} setInput={setInput}/>
            ), [outputType, input])}
            {input && inputType === 'elasticsearch' &&
                <ESInput input={input} handleInput={handleInput} setInput={setInput}/>
            }
            {useMemo(() => (input && inputType === 'api' &&
                <ApiInput input_type={inputType} input={input} handleInput={handleInputAPI} />
            ), [inputType, input])}
            <Paper sx={{
                p: 1,
                width:'100%',
                bgcolor:'#eeeeee',
                mt:2,
            }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1}}>
                    <Typography variant='subtitle2'>
                        * Output 설정
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                <TextField
                                    disabled
                                    id="output_type"
                                    label="Output Type"
                                    fullWidth
                                    value={outputType}
                                >
                                </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {useMemo(() => (((outputType === '') || (outputType === 'elasticsearch')) &&
                <ESOutput output={output} handleOutput={handleOutput} />
            ), [output])}
            {useMemo(() => (((outputType === '') || (outputType === 'elasticsearch')) &&
                <BaseMapping mapping={mappingValues} setMappingValues={setMappingValues} />
            ), [mappingValues])}
            {outputType === 'lrs' &&
                <LRSMapping
                    output={output}
                    handleOutput={handleOutput}
                    setOutput={setOutput}
                    mapping={mappingValues}
                    setMappingValues={setMappingValues}  />
            }

            <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3,
                }}
            >
                <Button
                    variant='contained'
                    onClick={navigateConfigList}
                    sx={{ height: '100%', ml:1 }}
                >
                    목록보기
                </Button>
                <Button
                    variant='contained'
                    onClick={handleCreateConfig}
                    sx={{ height: '100%', ml:1 }}
                >
                    저장하기
                </Button>
            </Grid>
            <RepositorySelectBox
                open={dialogOpen}
                setOpen={setDiaLogOpen}
                repository={repository}
                repositoryList={repositoryList}
                type={type}
                setInputTypeChange={handleInputType}
                setOutputTypeChange={handleOutputType}
                setRepositoryMapping={handleRepositorySelectMapping}>

            </RepositorySelectBox>
            <ConfirmDialog
                open={open}
                handleDialogAction={handleRepositoryCreate}
                handleDialogClose={handleCloseDialog}
                title={"저장소 생성"}
                text={message + " 현재 계정으로 저장소를 생성하시겠습니까?"}
            >
            </ConfirmDialog>
        </Container>

    );
}

export default RegisterConfig;