import {Grid, TextField} from "@mui/material";
import React, {useState, useEffect} from "react";
import {ConvertMapping} from "../../../../api/configAPI";
import Loading from "../../../Layout/Loading";

export function MappingPreview (props: any) {

    const [xAPIData, setXAPIData] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        ConvertMapping(props.mapping).then(value => {
            setXAPIData(value);
            setLoading(false);
        })
    }, [])

    if(loading) return <Loading />;

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 3}}>
            <Grid container spacing={1}>
                <TextField
                    multiline
                    fullWidth
                    minRows={10}
                    maxRows={20}
                    id='mapping_preview'
                    label='xAPI Preview'
                    value={xAPIData}
                />
            </Grid>
        </Grid>
    );
}