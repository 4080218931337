import React, {useEffect, useState} from "react";
 import {useNavigate} from "react-router-dom";
import {
    Button,
    Container,
    Grid,
    IconButton,
    Paper, Typography
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {DataGrid, GridCellValue, GridColDef} from "@mui/x-data-grid";

import Loading from "../Layout/Loading";
import {ConfirmDialog} from "../Layout/CustomDialog";
import {userListArrayType, ListUser, userListInterface,DeleteUser} from "../../api/userAPI";
import {userListInitial} from '../../api/definition';
import UserDetail from "./userDetail";

function UserConfig() {

    const [userData, setUserData] = useState<userListArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteUserOpen, setDeleteUserOpen] = useState<boolean>(false);
    const [deleteTargetName, setDeleteTargetName] = useState<string>('');

    const [open, setOpen] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(false);
    const [updateUser, setUpdateUser] = useState<userListInterface>(userListInitial());
    const navigator = useNavigate();
    const userColumns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'user_name', headerName: '사용자 이름', flex: 1},
        { field: 'user_id', headerName: '사용자 아이디', flex: 1 },
        { field: 'user_email', headerName: '사용자 이메일', flex:2},
        { field: 'authority', headerName: '권한', flex:1},
        { field: 'status', headerName: '상태', flex:1},
        {
            field: 'update', headerName: '수정', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleUserUpdate(params.getValue(params.id, 'id'))}
                    color='primary'
                    aria-label='update-user'
                >
                <CreateIcon/>
                </IconButton>
            )
        },
        {
            field: 'delete', headerName: '삭제', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleDeleteUserOpen(params.getValue(params.id, 'user_id'))}}
                    color='error'
                    aria-label='delete-user'
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
    ]

    const handleRefresh = () => {
        setLoading(true);
        if(window.sessionStorage.getItem('authorization') !== 'admin'){
            alert("현재 이 페이지에 접근할 권한이 없습니다.");
            navigator('/');
        }
        ListUser().then(data => {
            setUserData(data);
            setLoading(false);
        })
    }

    const handleUserUpdate = (param:GridCellValue) => {
        const user = userData.find(data => data.id === param);
        if(user) {
            setUpdateUser(user);
            setUpdate(true);
            setOpen(true);
        }
    }
    const handleDeleteUserOpen = (userId: GridCellValue) => {
        if (typeof userId === 'string') {
            setDeleteTargetName(userId);
            setDeleteUserOpen(true);
        } else {
            alert(`${userId} is not string type`);
        }
    }
    const handleDeleteUserClose = () => {
        setDeleteUserOpen(false);
    }

    const handleDeletUser = () => {
        setDeleteUserOpen(false);
        if (deleteTargetName !== '') {
            DeleteUser(deleteTargetName).then(value => {
                alert(value);
                handleRefresh();
            })
        } else {
            alert('empty name')
        }
    }
    useEffect(() => {
        if(window.sessionStorage.getItem('authorization') !== 'admin') {
            alert("현재 이 페이지에 접근할 권한이 없습니다.");
            navigator('/');
        }
        setLoading(true);
        ListUser().then(data => {
            setUserData(data);
            setLoading(false);
        })
        }, []);

     if (loading) return <Loading />;

    return (
        <Container maxWidth='xl' sx={{mt:10, bm:2}}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p:0,
                          display:'flex',
                          flexDirection:'row',
                          height:'10%',
                      }}>
                    <Paper sx={{
                        p: 1,
                        display: 'flex',
                        width: '100%',
                    }}>
                        <Grid container spacing={1}>
                            <Grid item lg={10} md={10} xs={10} sx={{
                                display : 'flex',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{ml:1}} variant='h5'>계정 관리</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                display: 'flex',
                                flexDirection:'row',
                                justifyContent:'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    onClick={() => setOpen(true)}
                                    sx={{height:'100%', boxShadow:"none"}}>
                                    신규생성
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                    sx = {{
                        mt:1,
                        p:0,
                        display:'flex',
                        flexDirection:'row',
                        height:'90%'
                    }}>
                    <Paper
                        sx={{
                            p:1,
                            display:'flex',
                            height:650,
                            width:'100%',
                        }}>
                        <DataGrid
                            columns={userColumns}
                            rows={userData}
                            pageSize={10}
                        />
                    </Paper>
                </Grid>
            </Grid>

                <UserDetail
                    open={open}
                    setOpen={setOpen}
                    update={update}
                    setUpdate={setUpdate}
                    setRefresh={handleRefresh}
                    value={updateUser}
                    users={userData}
                />
            <ConfirmDialog
                open={deleteUserOpen}
                handleDialogAction={handleDeletUser}
                handleDialogClose={handleDeleteUserClose}
                title={'Delete Config'}
                text={deleteTargetName + '을(를) 정말로 제거하시겠습니까?'}
            />

        </Container>
    )
}

export default UserConfig;