import React from "react";
import {Grid, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";

export function ActorForm (props: any) {

    const handleMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prefix: string) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace(prefix, '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping,
            [mappingKey]: value
        }
        if(props.name !== undefined){
            props.setMappingValues(mappingValues, props.name);
        }else{
            props.setMappingValues(mappingValues);
        }
    }

    const handleIFIChangeMapping = (event: SelectChangeEvent, field: string) => {
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping,
            ifi: {
                ...props.mapping.ifi,
                [field]: value
            }
        }
        if(props.name !== undefined){
            props.setMappingValues(mappingValues, props.name);
        }else{
            props.setMappingValues(mappingValues);
        }
    }

    const handleIFIMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prefix: string) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace(prefix, '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping,
            ifi: {
                ...props.mapping.ifi,
                [mappingKey]: value
            }
        }
        if(props.name !== undefined){
            props.setMappingValues(mappingValues, props.name);
        }else{
            props.setMappingValues(mappingValues);
        }
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        fullWidth
                        id={props.prefix+'name'}
                        label='Column of name'
                        value={props.mapping.name}
                        onChange={e => handleMapping(e, props.prefix)}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Select
                        required
                        fullWidth
                        value={props.mapping.ifi.property}
                        onChange={e => handleIFIChangeMapping(e, 'property')}
                    >
                        <MenuItem value="">IFI Select</MenuItem>
                        <MenuItem value="mbox">mbox</MenuItem>
                        <MenuItem value="mbox_sha1sum">mbox_sha1sum</MenuItem>
                        <MenuItem value="openid">openid</MenuItem>
                        <MenuItem value="account">account</MenuItem>
                    </Select>
                </Grid>
                {props.mapping.ifi.property === 'account' &&
                    <ActorIFIAccountForm mapping={props.mapping} handleMapping={handleIFIMapping} prefix={props.prefix+'ifi_'}/>
                }
                {props.mapping.ifi.property !== 'account' && props.mapping.ifi.property !== '' &&
                    <ActorIFIForm mapping={props.mapping} handleMapping={handleIFIMapping} prefix={props.prefix+'ifi_'}/>
                }
            </Grid>
        </Grid>
    );
}

function ActorIFIForm (props: any) {
    return (
        <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
                fullWidth
                id={props.prefix+'name'}
                label='Column of IFI value'
                value={props.mapping.ifi.name}
                onChange={e => props.handleMapping(e, props.prefix)}
            />
        </Grid>
    );
}

function ActorIFIAccountForm (props: any) {
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextField
                    fullWidth
                    id={props.prefix+'homepage'}
                    label='Homepage'
                    value={props.mapping.ifi.homepage}
                    onChange={e => props.handleMapping(e, props.prefix)}
                />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        fullWidth
                        id={props.prefix+'name'}
                        label='Column of name'
                        value={props.mapping.ifi.name}
                        onChange={e => props.handleMapping(e, props.prefix)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}