import React from 'react';
import {
    Grid,
    Paper, Typography, Box
    ,Divider
    ,Chip
    , Popover, TextField
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {grey} from "@mui/material/colors";

function DescriptionBox(props: any) {


    return (
        <Popover
             sx={{pointerEvents:'none'}}
             open={props.open}
              anchorEl={props.anchorEl}
              anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
              }}
             transformOrigin={{
             vertical: 'top',
             horizontal: 'left',
             }}
             onClose={props.handleEventClose}
             disableRestoreFocus
        >
            <Box sx={{width:300,p:1}}>
                <Divider sx={{m:1}}>
                   <Chip label="Description"></Chip>
                </Divider>
                <Box sx={{width:"100%", backgroundColor:"#F1F1F1", borderRadius:"5%",p:2}}>
                    <Typography>{props.description}</Typography>
                </Box>
            </Box>
        </Popover>
    );
}

export default DescriptionBox;