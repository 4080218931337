import {Button, Grid, IconButton, MenuItem, Select, Paper, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {
    actorLRSInterface, contextActivityInitial,
    contextActivityInterface,
    contextLRSInterface,
} from "../../../../api/definition";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {ActorForm} from "./ActorForm";
import {ExtensionMap} from "./Extensions";
import {ObjectActivity} from "./Object";

export function ContextMap (props: any) {

    const [contextActivityType, setContextActivityType] = useState<string>('');

    const setContextMappingValues = (mappingValues: contextLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            context: mappingValues
        });
    }

    const setContextTypeMappingValues = (mappingValues: actorLRSInterface, name: string) => {
        setContextMappingValues({
            ...props.mapping.context,
            [name]: mappingValues
        });
    }

    const setContextActivityMappingValues = (mappingValues: contextActivityInterface, name: string) => {
        setContextTypeMappingValues({
            ...props.mapping.context.contextActivities,
            [name]: mappingValues
        }, 'contextActivities');
    }

     const handleContextMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace('context_', '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.context,
            [mappingKey]: value
        }
        setContextMappingValues(mappingValues);
    }

    const handleRemoveContextMapping = () => {
        delete props.mapping.context;
        props.setMappingValues({...props.mapping});
    }

    const handleContextActivityType = () => {
        setContextActivityMappingValues(contextActivityInitial(), contextActivityType);
    }

    const handleRemoveContextActivityMapping = (name: string) => {
        delete props.mapping.context.contextActivities[name];
        setContextMappingValues({...props.mapping.context});
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
            <Grid container spacing={1} sx={{mb:1}}>
                <Grid item  sx={{mr:2}}>
                    <Typography variant='h6' sx={{mt:0.4}}>
                        * context
                    </Typography>
                </Grid>
                <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleRemoveContextMapping}
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        id='context_registration'
                        label='registration'
                        value={props.mapping.context.registration}
                        onChange={handleContextMapping}
                    />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography variant='subtitle1'>
                        - contextActivities
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} sx={{mb:2}}>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Select
                                required
                                fullWidth
                                value={contextActivityType}
                                onChange={(e) => setContextActivityType(e.target.value)}
                            >
                                <MenuItem value="parent">parent</MenuItem>
                                <MenuItem value="grouping">grouping</MenuItem>
                                <MenuItem value="category">category</MenuItem>
                                <MenuItem value="other">other</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                              }}
                        >
                            <Button
                                variant='outlined' size='small'
                                sx={{ ml: 1, height: '100%'}}
                                onClick={handleContextActivityType}
                            >
                                추가
                            </Button>
                        </Grid>
                    </Grid>
                    {props.mapping.context.contextActivities.parent &&
                        <ContextActivity
                            name='parent'
                            mapping={props.mapping.context.contextActivities.parent}
                            setMappingValues={setContextActivityMappingValues}
                            handleRemoveContextActivityMapping={handleRemoveContextActivityMapping}
                        />
                    }
                    {props.mapping.context.contextActivities.grouping &&
                        <ContextActivity
                            name='grouping'
                            mapping={props.mapping.context.contextActivities.grouping}
                            setMappingValues={setContextActivityMappingValues}
                            handleRemoveContextActivityMapping={handleRemoveContextActivityMapping}
                        />
                    }
                    {props.mapping.context.contextActivities.category &&
                        <ContextActivity
                            name='category'
                            mapping={props.mapping.context.contextActivities.category}
                            setMappingValues={setContextActivityMappingValues}
                            handleRemoveContextActivityMapping={handleRemoveContextActivityMapping}
                        />
                    }
                    {props.mapping.context.contextActivities.other &&
                        <ContextActivity
                            name='other'
                            mapping={props.mapping.context.contextActivities.other}
                            setMappingValues={setContextActivityMappingValues}
                            handleRemoveContextActivityMapping={handleRemoveContextActivityMapping}
                        />
                    }
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography variant='subtitle1'>
                        - instructor
                    </Typography>
                </Grid>
                <ActorForm
                    prefix='context_instructor_'
                    name='instructor'
                    mapping={props.mapping.context.instructor}
                    setMappingValues={setContextTypeMappingValues}
                />
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography variant='subtitle1'>
                        - team
                    </Typography>
                </Grid>
                <ActorForm
                    prefix='context_team_'
                    name='team'
                    mapping={props.mapping.context.team}
                    setMappingValues={setContextTypeMappingValues}
                />
                <ExtensionMap
                    object='context_extensions'
                    name='extensions'
                    mapping={props.mapping.context}
                    setMappingValues={setContextMappingValues}
                />
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography variant='subtitle1'>
                        - etc
                    </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='context_revision'
                        label='revision'
                        value={props.mapping.context.revision}
                        onChange={handleContextMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='context_platform'
                        label='platform'
                        value={props.mapping.context.platform}
                        onChange={handleContextMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='context_language'
                        label='language'
                        value={props.mapping.context.language}
                        onChange={handleContextMapping}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        fullWidth
                        id='context_statement'
                        label='statement'
                        value={props.mapping.context.statement}
                        onChange={handleContextMapping}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

function ContextActivity(props: any) {

    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    width: '100%',
                    bgcolor: '#fefefe',
                    mb: 2,
                    mt: 1
                }}
            >
                <Grid container spacing={1}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                        <Typography variant='subtitle2' sx={{mt:0.4}}>
                            ■ {props.name}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={() => props.handleRemoveContextActivityMapping(props.name)}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                    <ObjectActivity
                        prefix={'context_activity_'+props.name+'_'}
                        name={props.name}
                        mapping={props.mapping}
                        setMappingValues={props.setMappingValues}
                    />
                </Grid>
            </Paper>
        </Grid>
    );
}