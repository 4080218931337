import {Grid, TextField} from "@mui/material";
import React from "react";
import {LanguageMap} from "./Language";
import {verbLRSInterface} from "../../../../api/definition";

export function VerbMap (props: any) {

    const setVerbMappingValues = (mappingValues: verbLRSInterface) => {
        props.setMappingValues({
            ...props.mapping,
            verb: mappingValues
        });
    }

    const handleVerbMapping = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace(props.prefix, '');
        let value: string = event.target.value;
        let mappingValues = {
            ...props.mapping.verb,
            [mappingKey]: value
        }
        setVerbMappingValues(mappingValues);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                        fullWidth
                        id={props.prefix+'id'}
                        label='ID of verb'
                        value={props.mapping.verb.id}
                        onChange={handleVerbMapping}
                    />
                </Grid>

                <LanguageMap
                    object={props.prefix}
                    name='display'
                    mapping={props.mapping.verb}
                    setMappingValues={setVerbMappingValues}
                />
            </Grid>
        </Grid>
    );
}