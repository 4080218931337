import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    Grid,
    IconButton,
    Paper, TextField, Toolbar,
    Typography,
    Select, SelectChangeEvent, MenuItem
} from '@mui/material';
import {DataGrid, GridCellValue, GridColDef} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import DescriptionBox from "../BoxComponent/DescriptionBox";

import {configArrayType, configWithStatusArrayType, ListConfigWithStatus} from '../../api/configAPI';
import {
    selectStartProcessArray,
    StartAllProcess,
    StartProcess,
    StopAllProcess,
    StopProcess,
    StartSelectProcess,
} from '../../api/processAPI';
import {TestProcess, TestResultInterface} from '../../api/testAPI';

import RefreshIcon from '@mui/icons-material/Refresh';
import Loading from "../Layout/Loading";
import {ConfirmDialog} from '../Layout/CustomDialog';


function Process() {
    const [configData, setConfigData] = useState<configWithStatusArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [startSelectOpen, setStartSelectOpen] = useState<boolean>(false);
    const [startOpen, setStartOpen] = useState<boolean>(false);
    const [stopOpen, setStopOpen] = useState<boolean>(false);
    const [testOpen, setTestOpen] = useState<boolean>(false);

    const [startTarget, setStartTarget] = useState<string | null>('');
    const [startTargetWriter, setStartTargetWriter] = useState<string>('');
    const [stopTarget, setStopTarget] = useState<string | null>('');
    const [stopTargetWriter, setStopTargetWriter] = useState<string>('');
    const [startTargetList, setStartTargetList] = useState<selectStartProcessArray>([]);

    const [testResult, setTestResult] = useState<Array<TestResultInterface>>([{
        name:'',
        time: '',
        result: [''],
        error: false,
    }]);
    const [testLoading, setTestLoading] = useState<boolean>(true);
    const [testResultName, setTestResultName] = useState<string>('');
    const [testResultTime, setTestResultTime] = useState<string>('');
    const [testResultContent, setTestResultContent] = useState<Array<string>>(['']);
    const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [searchData, setSearchData] = useState<configArrayType>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [page, setPageValue] = useState<number>(0);

    const configColumns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'writer', hide: true},
        { field: 'name', headerName: '프로세스명', flex: 1 },
        { field: 'cron', headerName: '실행주기', flex: 1 },
        { field: 'input_type', headerName: 'Input 타입', flex: 1 },
        { field: 'output_type', headerName: 'Output 타입', flex: 1 },
        { field: 'repository', headerName: '저장소', flex: 1},
        { field: 'status', headerName: '상태', width: 100 },
        {
            field: 'start', headerName: '실행', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleStartOpen(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='success'
                    aria-label='start-process'
                >
                    <PlayArrowIcon />
                </IconButton>
            )
        },
        {
            field: 'stop', headerName: '종료', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleStopOpen(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='error'
                    aria-label='stop-process'
                >
                    <StopIcon />
                </IconButton>
            )
        },
        {
            field: 'test', headerName: '테스트', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleTestOpen(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    aria-label='test-process'
                >
                    <ManageSearchIcon />
                </IconButton>
            )
        }
    ]

    const handleStartOpen = (configName: GridCellValue | null, writer: GridCellValue) => {
        if (configName === null) {
            setStartTarget(null);
        } else if (typeof configName === 'string' && typeof writer === 'string') {
            setStartTarget(configName);
            setStartTargetWriter(writer);
        }
        setStartOpen(true);
    }

    const handleStartClose = () => {
        setStartOpen(false);
    }

    const handleStart = () => {
        setStartOpen(false);
        if (startTarget === null) {
            StartAllProcess().then(value => {
                alert(value);
            })
        } else if (startTarget !== '') {
            StartProcess(startTarget, startTargetWriter).then(value => {
                alert(value);
            })
        } else {
            alert('empty name')
        }
    }

    const handleStopOpen = (configName: GridCellValue | [], writer: GridCellValue) => {
        if (configName === null) {
            setStopTarget(null);
        } else if (typeof configName === 'string' && typeof writer === 'string') {
            setStopTarget(configName);
            setStopTargetWriter(writer);
        }
        setStopOpen(true);
    }

    const handleStopClose = () => {
        setStopOpen(false);
    }

    const handleStop = () => {
        setStopOpen(false);
        if (stopTarget === null) {
            StopAllProcess().then(value => {
                alert(value);
            })
        } else if (stopTarget !== '') {
            StopProcess(stopTarget, stopTargetWriter).then(value => {
                alert(value);
            })
        } else {
            alert('empty name')
        }
    }

    const handleTestOpen = (configName: GridCellValue, writer: GridCellValue) => {
        if (typeof configName === 'string' && typeof writer === 'string') {
            setTestLoading(true);
            setTestOpen(true);
            TestProcess(configName, writer).then(value => {
                if (value[0].error) {
                    setTestResult(value);
                    setTestResultName('');
                    setTestResultTime('');
                    setTestResultContent([]);
                    setTestLoading(false);
                } else {
                    setTestResult(value);
                    setTestResultName(value[0]['name']);
                    setTestResultTime(value[0]['time']);
                    setTestResultContent(value[0]['result']);
                    setTestLoading(false);
                }
            })
        }

    }

    const handleTestClose = () => {
        setTestOpen(false);
    }

    const handleTestResultChange = (event: SelectChangeEvent) => {
        let targetName = event.target.value;

        let idx = testResult.findIndex(value => value.name === targetName);
        setTestResultName(testResult[idx]['name']);
        setTestResultTime(testResult[idx]['time']);
        setTestResultContent(testResult[idx]['result']);
    }

    const handleRefresh = () => {
        setLoading(true);
        ListConfigWithStatus().then(value => {
            setConfigData(value);
            setLoading(false);
        })
    }

    useEffect(() => {
        setLoading(true);
        ListConfigWithStatus().then(value => {
            setConfigData(value);
            setLoading(false);
        })
    }, [])

    const handleSelectStartAdd = (processid : any[]) => {
        if(processid.length === 0){
            setStartTargetList([]);
        } else{
            let configs:selectStartProcessArray = []
            configData.forEach(data => {
                if (processid.includes(data["id"])){
                    if(typeof data['name'] === 'string' && typeof data['writer'] === 'string') {
                        let value = {
                            'name': data['name'],
                            'writer': data['writer']
                        }
                        configs.push(value);
                    }

                }
            })

            setStartTargetList(configs);
        }
    }

    const handleSelectStartOpen = () => {

        if(startTargetList.length === 0) {
            alert("선택된 프로세스가 없습니다.");
        }else{
            setStartSelectOpen(true);
        }
    }

    const handleSelectStartClose = () => {
        setStartSelectOpen(false);
    }

    const handleSelectStart = () => {
        setStartSelectOpen(false);
        StartSelectProcess(startTargetList).then(value => {
            alert(value);
        })
    }

    const handleEventEnter = (event : React.MouseEvent<HTMLElement>) => {
        let rowid = event.currentTarget.dataset.id;
        let target = event.currentTarget;
        configData.forEach((data) => {
            if(rowid === data.id){
                if(data.description !== '' && data.description !== undefined){
                    setAnchorEl(target);
                    setDescriptionOpen(true);
                    setDescription(data.description);
                }
            }
        })
    }

    const handleEventClose = (event : React.MouseEvent<HTMLElement>) => {
        setDescriptionOpen(false);
        setAnchorEl(null);
    }

    const handleSearchMapping = (value: string) => {
        setSearchValue(value);
        let searchDatas: any[] = []
        configData.forEach((data) => {
            if(data['name'].includes(value) || data['description'].includes(value)){
                searchDatas.push(data);
            }
        })
        setSearchData(searchDatas);
        setPageValue(0);
    }

    if (loading) return <Loading />;

    // @ts-ignore
    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '10%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={4} md={4} sm={4} xs={4} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ml: 1}} variant='h5'>프로세스 실행관리</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <IconButton
                                    onClick={handleRefresh}
                                    aria-label='refresh'
                                >
                                    <RefreshIcon/>
                                </IconButton>
                                <Button
                                    variant='contained'
                                    onClick={() => {handleSelectStartOpen()}}
                                    sx={{ height: '100%', mr:1, boxShadow:"none"}}
                                >
                                    선택시작
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={() => {handleStartOpen(null, '')}}
                                    sx={{ height: '100%', mr:1, boxShadow:"none"}}
                                >
                                    전체시작
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={() => {handleStopOpen(null, '')}}
                                    sx={{ height: '100%'}}
                                >
                                    전체중지
                                </Button>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                fullWidth
                                id="searchBar"
                                label="프로세스 검색"
                                value={searchValue}
                                size='small'
                                onChange={e => handleSearchMapping(e.target.value)}>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '90%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            height: 650,
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            rows={searchValue === '' ? configData : searchData}
                            columns={configColumns}
                            page = {page}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick={true}
                            onSelectionModelChange={ids => handleSelectStartAdd(ids)}
                            onPageChange={n => setPageValue(n)}
                            componentsProps={{
                                row: {
                                    onMouseEnter : handleEventEnter,
                                    onMouseLeave : handleEventClose
                                }
                            }}
                        />
                        {descriptionOpen && <DescriptionBox open={descriptionOpen}
                                        description={description}
                                        handleEventClose={handleEventClose}
                                        anchorEl={anchorEl}
                             ></DescriptionBox>}
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={startSelectOpen}
                handleDialogAction={handleSelectStart}
                handleDialogClose={handleSelectStartClose}
                title={'Start Process'}
                text={'선택한 프로세스을(를) 정말로 시작하시겠습니까?'}
            />
            <ConfirmDialog
                open={startOpen}
                handleDialogAction={handleStart}
                handleDialogClose={handleStartClose}
                title={'Start Process'}
                text={(startTarget ? startTarget : '모든 프로세스') + '을(를) 정말로 시작하시겠습니까?'}
            />
            <ConfirmDialog
                open={stopOpen}
                handleDialogAction={handleStop}
                handleDialogClose={handleStopClose}
                title={'Stop Process'}
                text={(stopTarget ? stopTarget : '모든 프로세스') + '을(를) 정말로 중지하시겠습니까?'}
            />
            <Dialog
                open={testOpen}
                onClose={handleTestClose}
                maxWidth='sm'
                fullWidth
            >
                <Toolbar>
                    <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                            p:0,
                            display:'flex',
                            flexDirection: 'row',
                            hegiht: "50%"
                        }}>
                            <Grid container spacing={1} sx={{ alignItems:"center"}}>
                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <IconButton
                                            edge='start'
                                            color='error'
                                            onClick={handleTestClose}
                                            aria-label='close'
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        </Grid>
                                {testLoading
                                ?
                                    <Grid item lg={8} md={8} sm={8} xs={8}>
                                        <Typography variant='h5'>
                                        테스트 진행중
                                        </Typography>
                                    </Grid>
                                :
                                    <Grid item lg={7} md={7} sm={7} xs={7}>
                                            <Typography variant='h5'>
                                                {'테스트 결과 ' + testResultTime}
                                            </Typography>
                                    </Grid>
                                }
                                {
                                    ((!testLoading) && (testResult.length > 1)) &&
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <Select
                                            fullWidth
                                            id='test_result'
                                            value={testResultName}
                                            size='small'
                                            onChange={e => handleTestResultChange(e)}
                                        >
                                            {testResult.map((data) =>
                                                    <MenuItem value={data.name}>{data.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                {testLoading
                ?   <Container maxWidth='xl' sx={{
                        height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}>
                        <CircularProgress size={100}/>
                    </Container>
                :   <Paper
                        sx={{
                            pt: 0,
                            pl: 2,
                            pr: 2,
                            pb: 2,
                            overflow: 'auto',
                        }}
                    >

                        {testResultContent.map((value) =>
                        value) + '\n'}
                    </Paper>
                }

            </Dialog>
        </Container>
    );
}

export default Process;