import TISAxios from './Instance'
import {GridCellValue} from "@mui/x-data-grid";
import {repositoryAPIHostInterface} from "./definition";

export interface repositoryListInterface{
    id: string;
    name: string;
    input_type: string;
    input_host:string;
    output_type:string;
    output_host:string;
    status:string;
    description:string;
    writer:string;
}

// 신규 API 저장소 추가
interface repositoryConfigListInterface{
    id:string;
    name: string;
    status: boolean;
    description: string;
    input_type: string;
    input_hosts:Array<repositoryHostInterface | repositoryESHostInterface | repositoryAPIHostInterface>
    output_type:string;
    output_hosts:Array<repositoryESHostInterface | repositoryLRSHostInterface>
}

export type repositoryArrayType = Array<repositoryListInterface>;
export type repositoryConfigArrayType = Array<repositoryConfigListInterface>;
export interface repositoryInterface{
    name: string;
    status: boolean;
    description: string;
    input_type: string;
    input_hosts:Array<repositoryHostInterface | repositoryESHostInterface | repositoryAPIHostInterface>;
    output_type:string;
    output_hosts:Array<repositoryESHostInterface | repositoryLRSHostInterface>;
    writer:string;
}


export interface repositoryHostInterface{
    host: string;
    port: string;
    username: string;
    password: string;
    database: string;
}

export interface repositoryLRSHostInterface {
    url: string;
    username:string;
    password:string;
}

export interface repositoryESHostInterface {
    host: string;
    port: string;
    username: string;
    password: string;
}

export function instanceOfRepositoryInterface(object:any): object is repositoryInterface {
    return object.name !== undefined;
}

export async function CreateRepository(data: repositoryInterface){
    let message: string = 'error';
    await TISAxios.post(
        '/tis/repository/create', data
    ).then(function (response){
        if (response.data !== undefined){
            message = response.data.toString();
        }
    }).catch((error)=> {
        message = error;
    });

    return message
}

export async function CreateListRepository(data: repositoryArrayType){
    let repository:repositoryArrayType = [];
    await TISAxios.post(
        '/tis/repository/create_list', {repositories: data}
    ).then(function (response){
        if (response.data !== undefined){
            repository = response.data
        }
    }).catch((error)=> {
        repository = [];
    });

    return repository
}

export async function DeleteRepository(name: string, writer: string){
    let message: string = 'error';
    await TISAxios.post(
        `/tis/repository/delete`,
        {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function UpdateRepository(data: repositoryInterface) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/repository/update`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function CheckRepository(name: string, writer: string) {
    let data: repositoryInterface | string = 'error';
    await TISAxios.post(
        `/tis/repository/check`, {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = response.data[0];
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}


export async function ListRepository() {
    const newData: repositoryArrayType = [];
    await TISAxios.post(
        `/tis/repository/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; name: string;input_type:string;input_hosts:Array<repositoryHostInterface | repositoryESHostInterface>; output_type:string; output_hosts:Array<repositoryESHostInterface | repositoryLRSHostInterface>;status:boolean; description:string; writer:string }) => {

                newData.push({
                    'id': row._id,
                    'name': row.name,
                    'input_type':row.input_type,
                    'input_host':'' + row.input_hosts.length,
                    'output_type': row.output_type,
                    'output_host':'' +row.output_hosts.length,
                    'status': row.status ? 'Activate' : 'Disabled',
                    'description':row.description,
                    'writer':row.writer
                })
            });
        }
    }).catch(function (error) {

    });
    return newData;
}

export async function ListConfigRepository() {
    const newData: repositoryConfigArrayType = [];
    await TISAxios.post(
        `/tis/repository/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; name: string; input_type: string;input_hosts:Array<repositoryHostInterface | repositoryESHostInterface>;output_type:string;output_hosts:Array<repositoryESHostInterface | repositoryLRSHostInterface>; status:boolean; description:string }) => {
                newData.push({
                    'id': row._id,
                    'name': row.name,
                    'input_type':row.input_type,
                    'input_hosts': row.input_hosts,
                    'output_type': row.output_type,
                    'output_hosts': row.output_hosts,
                    'status': row.status,
                    'description':row.description
                })
            });
        }
    }).catch(function (error) {

    });
    return newData;
}

export async function RunRepositoryCheck(name: GridCellValue, writer: GridCellValue) {
    let message: boolean = false;

    await TISAxios.post(
        '/tis/process/repository_check',
        {name: name, writer: writer}
    ).then(function(response){
        if(response.data !== undefined) {
            message = response.data;
        }
    }).catch(function(error){
        message = error;
    })

    return message;
}