import TISAxios from './Instance';

interface codeListInterface {
    id: string;
    code: string;
    name: string;
    writer: string;
}

interface codeWithStatusInterface extends codeListInterface{
    status: string;
}

export type codeArrayType = Array<codeListInterface>;
export type codeWithStatusArrayType = Array<codeWithStatusInterface>;

export interface codeInterface {
    code: string;
    name: string;
    params: Array<codeParamsInterface>;
    writer: string;
}

export interface codeParamsInterface {
    code: string;
    name: string;
}

export function codeParamsInitial() {
    return {
        code: '',
        name: ''
    }
}

export function instanceOfCodeInterface(object: any): object is codeInterface {
    return object.code !== undefined;
}

export async function CreateCode(data: codeInterface) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/code/create`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function DeleteCode(code: string, writer: string) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/code/delete`,
        {code: code, writer:writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function UpdateCode(data: codeInterface) {
    let message: string = 'error';
    await TISAxios.post(
        `/tis/code/update`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function CheckCode(code: string, writer: string) {
    let data: codeInterface | string = 'error';
    await TISAxios.post(
        `/tis/code/check`, {code: code, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = response.data[0];
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

export async function ListCode() {
    const newData: codeArrayType = [];
    await TISAxios.post(
        `/tis/code/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; code: string; name: string; writer: string}) =>
                newData.push({
                    'id': row._id,
                    'code': row.code,
                    'name': row.name,
                    'writer': row.writer
                })
            );
        }
    }).catch(function (error) {

    });
    return newData;
}