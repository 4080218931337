import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Container,
    Grid,
    IconButton,
    Paper, Typography
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {DataGrid, GridCellValue, GridColDef} from '@mui/x-data-grid';

import Loading from '../Layout/Loading';
import {ConfirmDialog} from '../Layout/CustomDialog';

import {repositoryArrayType, ListRepository, DeleteRepository, RunRepositoryCheck} from '../../api/repositoryAPI';

function Repository(){

    const [repositoryData, setRepositoryData] = useState<repositoryArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteConfigOpen, setDeleteRepositoryOpen] = useState<boolean>(false);
    const [deleteTargetName, setDeleteTargetName] = useState<string>('');
    const [deleteTargetWriter, setDeleteTargetWriter] = useState<string>('');

    const repositoryColumns: GridColDef[] = [
        { field: 'id', hide:true},
        { field: 'writer', hide:true},
        { field: 'name', headerName: '저장소명', flex:2},
        { field: 'input_type', headerName: 'Input 타입', flex:1},
        { field: 'input_host', headerName: 'Input 호스트', flex:1},
        { field: 'output_type', headerName: 'Output 타입', flex:1},
        { field: 'output_host', headerName: 'Output 호스트', flex:1},
        { field: 'status', headerName:'상태', flex:1},
        {
            field: 'update', headerName: '수정', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {navigateUpdateRepository(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='primary'
                    aria-label='update-repository'
                >
                    <CreateIcon />
                </IconButton>
            )
        },
        {
            field: 'delete', headerName: '삭제', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleDeleteRepositoryOpen(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='error'
                    aria-label='delete-repository'
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
    ]

    const navigate = useNavigate();
    const navigateUpdateRepository = (repositoryName:GridCellValue, writer:GridCellValue) => {

        RunRepositoryCheck(repositoryName, writer).then((state) => {
            if(state){
                alert('현재 프로세스가 실행 중 입니다. 프로세스를 종료해주세요.');
            }else{
                let url = '/repository/' + repositoryName;
                navigate(url, {state:{repositoryName:repositoryName, writer:writer}});
            }
        })
    }

    const handleDeleteRepositoryOpen = (repositoryName: GridCellValue, writer:GridCellValue) => {
        if (typeof repositoryName === 'string' && typeof writer === 'string') {
            setDeleteTargetName(repositoryName);
            setDeleteTargetWriter(writer);
            setDeleteRepositoryOpen(true);
        } else {
            alert(`${repositoryName} is not string type`);
        }
    }

    const handleDeleteRepositoryClose = () => {
        setDeleteRepositoryOpen(false);
    }

    const handleDeleteRepository = () => {
        setDeleteRepositoryOpen(false);
        if (deleteTargetName !== '' && deleteTargetWriter !== '') {
            DeleteRepository(deleteTargetName, deleteTargetWriter).then(value => {
                alert(value);
                handleRefresh();
            })
        } else {
            alert('empty name')
        }
    }
    const handleRefresh = () => {
        setLoading(true);
        ListRepository().then(value => {
            setRepositoryData(value);
            setLoading(false);
        })
    }

    useEffect(() => {
        setLoading(true);
        ListRepository().then(value => {
            setRepositoryData(value);
            setLoading(false);
        })
    }, [])

    if (loading) return <Loading />;
    return (
        <Container maxWidth='xl' sx={{mt:10, bm:2}}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p:0,
                          display:'flex',
                          flexDirection:'row',
                          height:'10%',
                      }}>
                    <Paper sx={{
                        p: 1,
                        display: 'flex',
                        width:'100%',
                    }}>
                        <Grid container spacing={1}>
                            <Grid item lg={10} md={10} xs={10} sx={{
                                display : 'flex',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{ml:1}} variant='h5'>저장소 관리</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent:'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    onClick={() => {navigateUpdateRepository('new', '')}}
                                    sx={{height:'100%', boxShadow: "none"}}
                                    >
                                    신규생성
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}
                  sx = {{
                      mt:1,
                      p: 0,
                      display:'flex',
                      flexDirection:'row',
                      height:'90%'
                  }}>
                <Paper
                    sx={{
                        p:1,
                        display:'flex',
                        height:650,
                        width:'100%',
                    }}>
                    <DataGrid
                        rows={repositoryData}
                        columns={repositoryColumns}
                        pageSize={10}
                    />
                </Paper>
            </Grid>
            <ConfirmDialog
                open={deleteConfigOpen}
                handleDialogAction={handleDeleteRepository}
                handleDialogClose={handleDeleteRepositoryClose}
                title={'Delete Config'}
                text={deleteTargetName + '을(를) 정말로 제거하시겠습니까?'}
            />
        </Container>
    )
}

export default Repository