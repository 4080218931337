import React from "react";
import {Button, Grid} from "@mui/material";

export function JsonUpload (props: any) {
    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files != null) {
            const fileReader: FileReader = new FileReader();
            fileReader.readAsText(event.target.files[0], 'UTF-8');
            fileReader.onload = (event) => {
                if (event.target != null && typeof event.target.result === "string") {
                    props.setData(JSON.parse(event.target.result));
                } else {
                    alert('data is not valid');
                }
            }
        }
    }

    return (
        <Grid item>
            <input
                type='file'
                accept='.json'
                id='upload-file'
                hidden
                onChange={(e) => handleUpload(e)}
            />
            <label htmlFor='upload-file'>
                <Button
                    variant='outlined'
                    component='span'
                    sx={{ height: '100%'}}
                >
                    JSON 업로드
                </Button>
            </label>
        </Grid>
    );
}

export function JsonDownload (props: any) {
    const handleDownload = async () => {
        const json: string = JSON.stringify(props.getData());
        const blob = new Blob([json], {type: 'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = props.name + '.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Grid item>
            <Button
                variant='outlined'
                onClick={handleDownload}
                sx={{ ml: 1, height: '100%'}}
            >
                JSON 다운로드
            </Button>
        </Grid>
    );
}