import {Grid, TextField, Select, MenuItem,SelectChangeEvent, IconButton} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import React from "react";
import {inputAPIParamsInterface} from "../../../../api/definition";

export function APIInputHeaders(props: any) {
   return (
        <>
        {props.input &&
            props.input.map((value: inputAPIParamsInterface, index: number) =>
                <Grid container spacing={1} sx={{mb:1}}>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <TextField
                            required
                            fullWidth
                            id={'input_api_header_name_'+index}
                            label='Name'
                            value={value.name}
                            onChange={e => props.handleInput(e, index)}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <TextField
                            required
                            fullWidth
                            id={'input_api_header_description_'+index}
                            label='Description'
                            value={value.description}
                            onChange={e => props.handleInput(e, index)}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Select
                            required
                            fullWidth
                            id={'input_api_header_type_'+index}
                            value={value.type}
                            onChange={e => props.handleType(e, 'headers', index)}>
                            <MenuItem value="text">text</MenuItem>
                            <MenuItem value="numeric">numeric</MenuItem>
                            <MenuItem value="date">date</MenuItem>
                            <MenuItem value="list">list</MenuItem>
                            <MenuItem value="dict">dict</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <TextField
                            required
                            fullWidth
                            id={'input_api_header_value_'+index}
                            label='Value'
                            value={value.value}
                            onChange={e => props.handleInput(e, index)}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={() => props.handleRemove(index, 'headers')}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )
        }
        </>
    );
}