import TISAxios from './Instance'

export interface userListInterface{
    id: string;
    user_id:string;
    password:string;
    authority:string;
    status:string;
    user_name:string;
    user_email:string;
    user_phone:string;
    user_group:string;
    user_position:string;
}

export type userListArrayType = Array<userListInterface>

interface userInterface{
    user_id:string;
    password:string;
    authority:string;
    status:string;
    user_name:string;
    user_email:string;
    user_phone:string;
    user_group:string;
    user_position:string;
}

interface userUpdateInterface{
    user_id:string;
    password:string;
    authority:string;
    status:string;
    user_name:string;
    user_email:string;
    user_phone:string;
    user_group:string;
    user_position:string;
    isChange:boolean;
}

export function instanceOfUserConfigInterface(object: any): object is userInterface {
    return object.user_id !== undefined;
}

export async function CreateUser(data: userInterface){
    let message: string = 'error';
    await TISAxios.post(
        '/tis/user/create', data
    ).then(function(response){
        if (response.data !== undefined){
            message = response.data.toString();
        }
    }).catch(function(error){
        message = error;
    });

    return message;
}

export async function DeleteUser(user_id: string){
    let message: string = 'error';
    await TISAxios.post(
        '/tis/user/delete', {'user_id':user_id}
    ).then(function(response){
        if (response.data !== undefined){
            message = response.data.toString();
        }
    }).catch(function(error){
        message = error;
    });

    return message;
}

export async function UpdateUser(data: userUpdateInterface){
    let message: string = 'error';
    await TISAxios.post(
        '/tis/user/update', data
    ).then(function(response){
        if (response.data !== undefined){
            message = response.data.toString();
        }
    }).catch(function(error){
        message = error;
    });

    return message;
}

export async function CheckUser(name: string) {
    let data: userInterface | string = 'error';
    await TISAxios.post(
        `/tis/repository/check`, {name: name}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = response.data[0];
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

export async function ListUser() {
    const newData: userListArrayType = [];
    await TISAxios.post(
        `/tis/user/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; user_id :string; password:string; authority:string; status:string;user_name:string; user_email:string; user_phone:string;  user_group:string; user_position:string;}) => {

                newData.push({
                    'id': row._id,
                    'user_id': row.user_id,
                    'password':row.password,
                    'authority': row.authority,
                    'status': row.status,
                    'user_name': row.user_name,
                    'user_email': row.user_email,
                    'user_phone': row.user_phone,
                    'user_group':row.user_group,
                    'user_position':row.user_position
                })
            });
        }
    }).catch(function (error) {

    });
    return newData;
}