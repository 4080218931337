import {Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import React from "react";
import {propertyInterface, propertyInitial} from "../../../../api/definition";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export function LanguageMap (props: any) {

    const setLanguageValues = (list: Array<propertyInterface>) => {
        props.setMappingValues({
            ...props.mapping,
            [props.name]: list
        });
    }

    const handleAddLanguage = () => {
        setLanguageValues([...props.mapping[props.name], propertyInitial()])
    }

    const handleRemoveLanguage = (index: number) => {
        let list = [...props.mapping[props.name]];
        list.splice(index, 1);
        setLanguageValues(list);
    }

    const handleMappingLanguage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        let mappingKey: string = event.target.id;
        mappingKey = mappingKey.replace(props.object + props.name + index + '_', '');
        let list = [...props.mapping[props.name]];
        list[index] = {
            ...list[index],
            [mappingKey]: event.target.value,
        };
        setLanguageValues(list);
    }

    return (
        <Grid item container spacing={1} lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant='subtitle1'>
                    - {props.name}
                </Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}
                  sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                  }}
            >
                <Button
                    variant='outlined' size='small'
                    sx={{ ml: 1, height: '100%'}}
                    onClick={handleAddLanguage}
                >
                    추가
                </Button>
            </Grid>
            {props.mapping[props.name] &&
                props.mapping[props.name].map((value: propertyInterface, index: number) =>
                    <LanguageList
                        key={props.object + props.name + index}
                        id={props.object + props.name + index}
                        value={value}
                        index={index}
                        handleRemoveLanguage={handleRemoveLanguage}
                        handleMappingLanguage={handleMappingLanguage}
                    />
            )}
        </Grid>
    );

}

function LanguageList (props: any) {
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <TextField
                        required
                        fullWidth
                        id={props.id + '_id'}
                        label='Language'
                        value={props.value.id}
                        onChange={e => props.handleMappingLanguage(e, props.index)}
                    />
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        required
                        fullWidth
                        id={props.id + '_value'}
                        label='Column of name'
                        value={props.value.value}
                        onChange={e => props.handleMappingLanguage(e, props.index)}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={() => props.handleRemoveLanguage(props.index)}
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}