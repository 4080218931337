import {Grid, TextField, Select, MenuItem,SelectChangeEvent} from "@mui/material";
import React from "react";

export function APIInputAuth(props: any) {
    return (
        <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Select
                    fullWidth
                    id='input_api_auth_type'
                    value={props.input.auth_type}
                    onChange={props.handleType}>
                    <MenuItem value="No Auth">No Auth</MenuItem>
                    <MenuItem value="Basic Auth">Basic Auth</MenuItem>
                    <MenuItem value="Bearer Token">Bearer Token</MenuItem>
                </Select>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                    fullWidth
                    label="Basic Auth Username"
                    id='input_api_auth_username'
                    value={props.input.auth.username}
                    onChange={props.handleInput}/>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                    fullWidth
                    label="Basic Auth Password"
                    id='input_api_auth_password'
                    value={props.input.auth.password}
                    onChange={props.handleInput}/>
            </Grid>
        </Grid>
    )
}