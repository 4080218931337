import {
    Grid,
    TextField,
    IconButton
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {repositoryHostInterface} from "../../../api/repositoryAPI";

export function RepositoryHostInput(props: any){
    const handleMappingHost = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index:number) =>{
        let hosts = [...props.value.input_hosts];
        let key = event.target.id.slice(0, -index.toString().length).replace('_input_', '');
        hosts[index] = {
            ...hosts[index],
            [key] : event.target.value
        };

        props.handleChangeHost({
            ...props.value,
            'input_hosts' : hosts
        })

    }

    const handleRemoveHost = (index: number) => {
        let hosts = [...props.value.input_hosts];
        hosts.splice(index, 1);
        props.handleChangeHost({
            ...props.value,
            'input_hosts' : hosts
        })
    }

    return (
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        {props.value.input_hosts &&
                            props.value.input_hosts.map((value:repositoryHostInterface, index: number) =>
                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'host_input_' + index}
                                                label='Host'
                                                value={value.host}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'port_input_' + index}
                                                label='Port'
                                                value={value.port}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                         </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'username_input_' + index}
                                                label='Username'
                                                value={value.username}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'password_input_' + index}
                                                label='Password'
                                                type='password'
                                                value={value.password}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                            <TextField
                                                fullWidth
                                                id={'database_input_' + index}
                                                label={'Database'}
                                                value={value.database}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1} sx={{
                                            display:'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => handleRemoveHost(index)}
                                                >
                                                <RemoveCircleOutlineIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
    )
}