import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Toolbar,
    IconButton,
} from '@mui/material';
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

export function ConfirmDialog(props: any){

    const handleDialogAction = () => {
        props.handleDialogAction();
    }

    const handleDialogClose = () => {
        props.handleDialogClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogClose}
        >
            <DialogTitle id='status-dialog'>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='status-dialog-description'>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogAction}>예</Button>
                <Button onClick={handleDialogClose}>아니오</Button>
            </DialogActions>
        </Dialog>
    );
}

export function LayerDialog(props: any){

    const handleDialogClose = () => {
        props.handleDialogClose();
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={handleDialogClose}
        >
            <Toolbar>
                <IconButton
                    edge='start'
                    color='error'
                    onClick={handleDialogClose}
                    aria-label='close'
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <DialogTitle id='status-dialog'>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}
