import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Grid,
    IconButton, Paper,
    Typography
} from '@mui/material';
import {DataGrid, GridCellValue, GridColDef} from '@mui/x-data-grid';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

import {ListSince, ResetAllSince, ResetSince, SinceArrayType, SinceInterface} from '../../api/sinceAPI';
import Loading from "../Layout/Loading";
import {ConfirmDialog} from '../Layout/CustomDialog';
import {SinceInitial} from "../../api/definition";

function Since() {
    const [sinceData, setSinceData] = useState<SinceArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [resetTarget, setResetTarget] = useState<SinceInterface>(SinceInitial());
    const [resetMessage, setResetMessage] = useState<string>('');
    const [resetOpen, setResetOpen] = useState<boolean>(false);

    const sinceColumns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'writer', hide: true},
        { field: 'name', headerName: '프로세스명', flex: 1 },
        { field: 'repository', headerName:'저장소', flex:1},
        { field: 'host', headerName:'호스트', flex:1},
        { field: 'database', headerName:'데이터베이스', flex:1},
        { field: 'since', headerName: '증분 시간', flex: 1 },
        {
            field: 'reset', headerName: '리셋', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleResetOpen(params.getValue(params.id, 'id'))}}
                    color='error'
                    aria-label='reset-since'
                >
                    <RestartAltIcon />
                </IconButton>
            )
        }
    ]

    const handleResetOpen = (id : GridCellValue) => {
        if (id === null) {
            setResetTarget(SinceInitial());
            setResetMessage('');
        } else if (typeof id === 'string') {
            let target = sinceData.find(data => data.id === id);
            if(target !== undefined) {
                setResetTarget(target);
                setResetMessage('프로세스 : ' + target.name +'\n저장소 : ' + target.repository  + '\n호스트 : ' + target.host + '\n데이터베이스 : ' + target.database + '\n 해당 프로세스 since'  )
            }else{
                alert("Undefined");
                setResetMessage('');
            }
        }
        setResetOpen(true);
    }

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleReset = () => {
        setResetOpen(false);
        if (resetTarget['id'] === '') {
            ResetAllSince().then(value => {
                alert(value);
                handleRefresh();
            })
        } else if (resetTarget) {
            ResetSince(resetTarget).then(value => {
                alert(value);
                handleRefresh();
            })
        } else {
            alert('empty name')
        }
    }

    const handleRefresh = () => {
        setLoading(true);
        ListSince().then(value => {
            setSinceData(value);
            setLoading(false);
        })
    }

    useEffect(() => {
        setLoading(true);
        ListSince().then(value => {
            setSinceData(value);
            setLoading(false);
        })
    }, [])

    if(loading) return <Loading />;

    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '10%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={10} md={10} sm={10} xs={10} sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <Typography sx={{ml: 1}} variant='h5'>프로세스별 Since 현황</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                            }}
                            >
                                <IconButton
                                    onClick={handleRefresh}
                                    aria-label='refresh'
                                >
                                    <RefreshIcon/>
                                </IconButton>
                                <Button
                                    variant='contained'
                                    onClick={() => {handleResetOpen(null)}}
                                    sx={{ height: '100%',  boxShadow:"none"}}
                                >
                                    전체리셋
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '90%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            height: 650,
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            rows={sinceData}
                            columns={sinceColumns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={resetOpen}
                handleDialogAction={handleReset}
                handleDialogClose={handleResetClose}
                title={'Delete Config'}
                text={(resetTarget['id'] !== '' ? resetMessage : '모든 데이터') + '을(를) 정말로 리셋하시겠습니까?'}
            />
        </Container>
    );
}

export default Since;