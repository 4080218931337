import {
    Grid,
    TextField,
    IconButton,
    Select,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {repositoryLRSHostInterface} from "../../../api/repositoryAPI";
import {repositoryESHostInitial, repositoryLRSHostInitial} from "../../../api/definition";
import {repositoryInterface} from "../../../api/repositoryAPI";

export function RepositoryLRSHostInput(props: any){
    const handleMappingHost = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index:number) =>{
        let hosts = [...props.value.output_hosts];
        let key = event.target.id.slice(0, -index.toString().length).replace('_output_', '');
        hosts[index] = {
            ...hosts[index],
            [key] : event.target.value
        };

        props.handleChangeHost({
            ...props.value,
            ['output_hosts'] : hosts
        })

    }
    const handleRemoveHost = (index: number) => {
        let hosts = [...props.value.output_hosts];
        hosts.splice(index, 1);
        props.handleChangeHost({
            ...props.value,
            ['output_hosts'] : hosts
        })
    }

    const handleInputTypeChange = (event: SelectChangeEvent) => {
        let data:repositoryInterface = {...props.value};
        data.output_type = event.target.value;
        data.output_hosts.length=0;
        if(data.output_type ==='elasticsearch'){
            data.output_hosts.push(repositoryESHostInitial());
        }else{
            data.output_hosts.push(repositoryLRSHostInitial());
        }
        props.handleChangeHost(data);
    }
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Select
                                required
                                fullWidth
                                id='output_type'
                                value={props.value.output_type}
                                onChange={e => handleInputTypeChange(e)}>
                                <MenuItem value="elasticsearch">Elasticsearch</MenuItem>
                                <MenuItem value="lrs">LRS</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                    <Grid container spacing={1}>
                        {props.value.output_hosts &&
                            props.value.output_hosts.map((value:repositoryLRSHostInterface, index: number) =>
                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={5} md={5} sm={5} xs={5}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'url_output_' + index}
                                                label='Request URL'
                                                value={value.url}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'username_output_' + index}
                                                label='Username'
                                                value={value.username}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'password_output_' + index}
                                                label='Password'
                                                type='password'
                                                value={value.password}
                                                onChange={e => handleMappingHost(e, index)}
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1} sx={{
                                            display:'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => handleRemoveHost(index)}
                                                >
                                                <RemoveCircleOutlineIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}