import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container,
    Grid,
    IconButton,
    Paper, TextField, Typography
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {DataGrid, GridCellValue, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import DescriptionBox from "../BoxComponent/DescriptionBox";

import Loading from '../Layout/Loading';
import {ConfirmDialog} from '../Layout/CustomDialog';

import {configArrayType, DeleteConfig, ListConfig, ActivateProcess, StatusUpdate, RunConfigCheck} from '../../api/configAPI';

function Config() {

    const [configData, setConfigData] = useState<configArrayType>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteConfigOpen, setDeleteConfigOpen] = useState<boolean>(false);
    const [deleteTargetName, setDeleteTargetName] = useState<string>('');
    const [deleteTargetWriter, setDeleteTargetWriter] = useState<string>('');
    const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [searchData, setSearchData] = useState<configArrayType>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [page, setPageValue] = useState<number>(0);

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const configColumns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'writer', hide: true },
        { field: 'name', headerName: '프로세스명', flex: 1 },
        { field: 'cron', headerName: '실행주기', flex: 1 },
        { field: 'input_type', headerName: 'Input 타입', flex: 1 },
        { field: 'output_type', headerName: 'Output 타입', flex: 1 },
        { field: 'repository', headerName: '저장소', flex: 1},
        {
            field: 'update', headerName: '수정', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {navigateUpdateConfig(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='primary'
                    aria-label='update-config'
                >
                    <CreateIcon />
                </IconButton>
            )
        },
        {
            field: 'delete', headerName: '삭제', width: 80,
            sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {handleDeleteConfigOpen(params.getValue(params.id, 'name'), params.getValue(params.id, 'writer'))}}
                    color='error'
                    aria-label='delete-config'
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
    ]

    const navigate = useNavigate();

    const navigateUpdateConfig = (configName: GridCellValue, writer:GridCellValue) => {
        RunConfigCheck(configName, writer).then((state) => {
            if(state){
                alert('현재 프로세스가 실행 중 입니다. 프로세스를 종료해주세요.');
            }else{
                let url = '/update_config/' + configName;
                navigate(url, {state:{configName:configName, writer:writer}});
            }
        });
    };

    const handleDeleteConfigOpen = (configName: GridCellValue, writer: GridCellValue) => {
        RunConfigCheck(configName, writer).then((state) => {
            if(state){
                alert('현재 프로세스가 실행 중 입니다. 프로세스를 종료해주세요.');
            }else{
                if (typeof configName === 'string' && typeof writer === 'string') {
                    setDeleteTargetName(configName);
                    setDeleteTargetWriter(writer);
                    setDeleteConfigOpen(true);
                } else {
                    alert(`${configName} is not string type`);
                    }
                }
        });
    }

    const handleDeleteConfigClose = () => {
        setDeleteConfigOpen(false);
    }

    const handleDeleteConfig = () => {
        setDeleteConfigOpen(false);
        if (deleteTargetName !== '') {
            DeleteConfig(deleteTargetName,deleteTargetWriter).then(value => {
                alert(value);
                handleRefresh();
            })
        } else {
            alert('empty name')
        }
    }

    const handleRefresh = () => {
        setLoading(true);
        ListConfig().then(value => {
            setConfigData(value);
            setLoading(false);
        })
    }

    const handleEventEnter = (event : React.MouseEvent<HTMLElement>) => {
        let rowid = event.currentTarget.dataset.id;
        let target = event.currentTarget;
        configData.forEach((data) => {
            if(rowid === data.id){
                if(data.description !== '' && data.description !== undefined){
                    setAnchorEl(target);
                    setDescriptionOpen(true);
                    setDescription(data.description);
                }
            }
        })
    }

    const handleEventClose = (event : React.MouseEvent<HTMLElement>) => {
        setDescriptionOpen(false);
        setAnchorEl(null);
    }

    const handleSearchMapping = (value: string) => {
        setSearchValue(value);
        let searchDatas: any[] = [];
        configData.forEach((data) => {
            if(data['name'].includes(value) || data['description'].includes(value)){
                searchDatas.push(data);
            }
        })
        setSearchData(searchDatas);
        setPageValue(0);

    }
    // state update
    const handleActivateState = (process_ids:GridSelectionModel) => {

        setSelectionModel(process_ids);
        if(process_ids.length === configData.length){
            StatusUpdate(configData,[]);
        }else if(process_ids.length === 0){
            StatusUpdate([],configData);
        }else {
            const start = page * 10
            const changeModels = configData.slice(start, start + 10);
            const activateModels = changeModels.filter(data => process_ids.includes(data.id));
            const disabledModels = changeModels.filter(data => !process_ids.includes(data.id));
            StatusUpdate(activateModels, disabledModels);
        }
    }

    useEffect(() => {
        setLoading(true);
        ListConfig().then(value => {
            setConfigData(value);
            setLoading(false);
        });

        ActivateProcess().then(activation => {
                setSelectionModel(activation);
        });
    }, [])

    if(loading) return <Loading />;

    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '10%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={8} md={8} sm={8} xs={8} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ml: 1}} variant='h5'>프로세스 등록관리</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <IconButton
                                    onClick={handleRefresh}
                                    aria-label='refresh'
                                >
                                    <RefreshIcon/>
                                </IconButton>
                                <Button
                                    variant='contained'
                                    onClick={() => {navigateUpdateConfig('new', '')}}
                                    sx={{ height: '100%',  boxShadow:"none"}}
                                >
                                    신규생성
                                </Button>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                fullWidth
                                id="searchBar"
                                label="프로세스 검색"
                                size='small'
                                value={searchValue}
                                onChange={e => handleSearchMapping(e.target.value)}>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          height: '90%',
                      }}
                >
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            height: 650,
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            rows={searchValue === '' ? configData : searchData}
                            columns={configColumns}
                            page = {page}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            selectionModel={selectionModel}
                            onPageChange={n => setPageValue(n)}
                            componentsProps={{
                                row: {
                                    onMouseEnter : handleEventEnter,
                                    onMouseLeave : handleEventClose,
                                }
                            }}
                        />
                        {descriptionOpen && <DescriptionBox open={descriptionOpen}
                                        description={description}
                                        handleEventClose={handleEventClose}
                                        anchorEl={anchorEl}
                             ></DescriptionBox>}
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={deleteConfigOpen}
                handleDialogAction={handleDeleteConfig}
                handleDialogClose={handleDeleteConfigClose}
                title={'Delete Config'}
                text={deleteTargetName + '을(를) 정말로 제거하시겠습니까?'}
            />
        </Container>
    );
}

export default Config;