import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
    Paper
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {
    codeInterface,
    instanceOfCodeInterface,
    codeParamsInterface,
    CreateCode,
    UpdateCode,
    CheckCode, codeParamsInitial,
} from '../../api/codeAPI';
import {useParams, useLocation} from "react-router-dom";
import {JsonDownload, JsonUpload} from "../Layout/CustomButton";

function RegisterCode() {

    const codeId = useLocation().state.code;
    const writer = useLocation().state.writer;
    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [codeParamsValues, setCodeParamsValues] = useState<Array<codeParamsInterface>>([codeParamsInitial()]);
    const [codeWriter, setCodeWriter] = useState<string>('');

    useEffect(() => {
        if(codeId !== undefined && codeId !== 'new') {
            CheckCode(codeId, writer).then(value => {
                setData(value);
            })
        }
    }, [codeId])

    const getData = () => {
        let data: codeInterface = {
            'code': code,
            'name': name,
            'params': codeParamsValues,
            'writer': codeWriter,
        };

        return data;
    }

    const setData = (value: any) => {
        try {
            if (instanceOfCodeInterface(value)) {
                setName(value.name);
                setCode(value.code);
                setCodeParamsValues(value.params);
                setCodeWriter(value.writer);
            } else {
                alert('data is not valid');
            }
        } catch (error) {
            alert('data is not valid');
        }
    }

    const handleName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName((event.target as HTMLTextAreaElement).value);
    };

    const handleCode = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode((event.target as HTMLTextAreaElement).value);
    };

    const handleCodeParams = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        let list = [...codeParamsValues];
        list[index] = {
            ...list[index],
            [event.target.id.slice(0, -index.toString().length)]: event.target.value,
        };
        setCodeParamsValues(list);
    };

    const handleAddCodeParams = () => {
        setCodeParamsValues([...codeParamsValues, codeParamsInitial()]);
    }

    const handleRemoveCodeParams = (index: number) => {
        let list = [...codeParamsValues];
        list.splice(index, 1);
        setCodeParamsValues(list);
    };

    const handleCreateCode = () => {
        if(codeId !== undefined && codeId !== 'new') {
            UpdateCode(getData()).then(value => {
                alert(value);
            })
        } else {
            CreateCode(getData()).then(value => {
                alert(value);
            })
        }
    }

    return (
        <Container maxWidth='xl' sx={{ mt: 10, mb: 2 }}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                <Grid container spacing={1}>
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                        <Typography sx={{ml: 1}} variant='h5'>새 코드 생성</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                    >
                        <JsonUpload setData={setData} />
                        <JsonDownload name={code} getData={getData} />
                    </Grid>
                </Paper>
            </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <TextField
                            id='code'
                            label='code'
                            value={code}
                            fullWidth
                            onChange={handleCode}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <TextField
                            required
                            id='name'
                            label='name'
                            value={name}
                            fullWidth
                            onChange={handleName}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
                <Grid container spacing={1}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                        <Typography variant='subtitle1'>Params - length: {codeParamsValues.length}</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}
                          sx={{
                              display: 'flex',
                              justifyContent: 'center'
                          }}
                    >
                        <Button
                            variant='contained'
                            onClick={handleAddCodeParams}
                        >
                            코드추가
                        </Button>
                    </Grid>
                {codeParamsValues.map((value, index) =>
                    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <Grid container spacing={1}>
                             <Grid item lg={5} md={5} sm={5} xs={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id={'code'+index}
                                    label='Code'
                                    value={value.code}
                                    onChange={e => handleCodeParams(e, index)}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id={'name'+index}
                                    label='Name'
                                    value={value.name}
                                    onChange={e => handleCodeParams(e, index)}
                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={() => handleRemoveCodeParams(index)}
                                >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3,
                }}
            >
                <Button
                    variant='contained'
                    onClick={handleCreateCode}
                    sx={{ height: '100%' }}
                >
                    저장
                </Button>
            </Grid>
        </Container>
    );
}

export default RegisterCode;