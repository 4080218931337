import {Container, Paper, Grid, TextField, Typography, Button} from "@mui/material";
import React, {useState, useEffect} from "react";
import {MappingGuide} from "../../../../api/configAPI";
import Loading from "../../../Layout/Loading";

export function GuideView (props: any) {

    const [xapiguide, setxAPIData] = useState<String>('');
    const [sqlguide, setSQLData] = useState<String>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [name, setName] = useState<String>('xapi');

    useEffect(() => {
        setLoading(true);
        MappingGuide().then(value => {
            let guide = value.split("&&");
            let xapi = guide[0];
            let sql = guide[1];

            setxAPIData(xapi.replaceAll(/(\\n)/g, "\n").replace("\"", ""));
            setSQLData(sql.replaceAll(/(\\n)/g, "\n").replace("\"", ""));
            setLoading(false);
        })
    }, [])

    if(loading) return <Loading />;

    return (
        <Container maxWidth='xl' sx={{mt:10, mb:2}}>
            <Grid container spacing={1}>
                <Grid item lg = {12} md={12} sm={12} xs={12}
                      sx={{
                          p:0,
                          display:'flex',
                          flexDirection:'row',
                          height:'10%',
                      }}>
                    <Paper sx={{p:1 , display:'flex', width:'100%'}}>
                         <Typography sx={{ml:1}} variant='h5'>맵핑 가이드</Typography>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                    sx={{
                        p:0,
                        display:'flex',
                        flexDirection:'row',
                        height:'90%'
                    }}>
                    <Paper sx={{p:1, height:700, width:'100%'}}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 3}}>
                            <Grid container spacing={1}>
                                {name === 'xapi' &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        minRows={25}
                                        maxRows={25}
                                        id='mapping_guide'
                                        label='xAPI Mapping Guide'
                                        value={xapiguide}
                                    />
                                </Grid>
                                }
                                {name === 'sql' &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        minRows={25}
                                        maxRows={25}
                                        id='sql_mapping_guide'
                                        label='SQL Mapping Guide'
                                        value={sqlguide}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                                 <Grid item lg={6} md={6} sm={6} xs={6}
                                  sx={{
                                      mt:1,
                                      display: 'flex',
                                      justifyContent: 'flex-center'
                                  }}
                            >
                                <Button
                                    variant='contained'
                                    value='xapi'
                                    sx={{ ml: 1, height: '100%'}}
                                    onClick={() => {setName('xapi')}}
                                >
                                    xAPI 가이드
                                </Button>
                                <Button
                                    variant='contained'
                                    value='sql'
                                    sx={{ ml: 1, height: '100%'}}
                                    onClick={() => {setName('sql')}}
                                >
                                    SQL 가이드
                                </Button>
                         </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
