// 기본 Input Type
import {Grid, TextField} from "@mui/material";
import React from "react";
export function ESInput(props: any){

    return (
        <Grid container spacing={1} sx={{ mt: 1}}>
            <Grid item lg={3} md={3} sm={3} xs={3} >
                <TextField
                required
                fullWidth
                id='input_index'
                label='Input Index'
                value={props.input.index}
                onChange={props.handleInput}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3} >
                <TextField
                required
                fullWidth
                id='input_id'
                label='Input ID'
                value={props.input.id}
                onChange={props.handleInput}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <TextField
                fullWidth
                id='input_since'
                label='Input Since'
                value={props.input.since}
                onChange={props.handleInput}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                required
                multiline
                fullWidth
                id='input_query'
                label='Input Query'
                value={props.input.query}
                onChange={props.handleInput}
                />
            </Grid>
        </Grid>
    );
}



